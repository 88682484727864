/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Button, Text, TextField, View } from '@az/starc-ui';
import { Table } from '@shared/components/Table/Table.tsx';
import { TableStylingVariants } from '@shared/components/Table/tableConstants.ts';

import { DEFAULT_PAGE, PAGE_SIZE } from '@shared/constants/constants.ts';

import { useTranslation } from 'react-i18next';
import styles from '@mdm/pages/RDM/Role/RoleList.module.scss';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle.tsx';
import { CAPABILITY_GROUP_ROWS, ROLE_ROWS } from '@shared/components/Table/tableMockData.ts';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PAGE_URLS } from '@shared/constants/routes.ts';
import { FormLabel } from '@shared/components/FormLabel/FormLabel.tsx';
import { ColumnParam } from '@shared/components/Table/Table.types.ts';
import { mapGroupPermissionTableRows } from '@mdm/utils/table/tableUtils.tsx';
import {
  PermissionListType,
  PermissionType,
} from '@mdm/pages/RDM/User/Permission/PermissionList.types.ts';

type RolePermissions = {
  [roleId: string]: {
    [groupId: string]: { permission: PermissionType[]; roleId: string; groupId: string };
  };
};

export const PermissionList = () => {
  /* State */
  const [columns, setColumns] = useState<ColumnParam[]>([]);
  const [rows, setRows] = useState<PermissionListType[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  /* Constants */
  const { t } = useTranslation();
  const navigate = useNavigate();

  /* Function */
  const setRowData = useCallback(() => {
    const groupByRoleId = ROLE_ROWS.reduce(
      (
        acc: Record<
          string,
          Record<string, { permission: PermissionType[]; roleId: string; groupId: string }>
        >,
        role
      ) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        acc[role.roleId] = CAPABILITY_GROUP_ROWS.reduce((groupAcc: RolePermissions, group) => {
          groupAcc[group.groupId] = {};
          return groupAcc;
        }, {});
        return acc;
      },
      {}
    );

    CAPABILITY_GROUP_ROWS.forEach((group) => {
      group.capabilities.forEach((capability) => {
        capability.permissions.forEach((permission) => {
          if (groupByRoleId[permission.roleId]?.[group.groupId]) {
            const groupPermissions =
              groupByRoleId[permission.roleId][group.groupId].permission || [];
            groupPermissions.push(permission);
            groupByRoleId[permission.roleId][group.groupId].permission = groupPermissions;
          } else {
            groupByRoleId[permission.roleId][group.groupId] = {
              roleId: permission.roleId,
              permission: [permission],
              groupId: group.groupId,
            };
          }
        });
      });
    });

    let rowData: PermissionListType[] = ROLE_ROWS.map((role) => {
      const row = { roleId: role.roleId };
      CAPABILITY_GROUP_ROWS.forEach((group) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        row[group.groupId] = {
          permission: groupByRoleId[role.roleId][group.groupId].permission,
        };
      });
      return row;
    });

    if (searchQuery) {
      rowData = rowData.filter((row) =>
        row.roleId.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    }

    setRows([...rowData]);
  }, [searchQuery]);

  const setColumnData = useCallback(() => {
    const columnData = [
      {
        label: 'CreateRoleModal.RoleName',
        id: 'roleId',
        textAlign: 'start' as const,
        width: 'calc(var(--st-unit-20) * 2)',
        isSorted: false,
      },
      ...CAPABILITY_GROUP_ROWS.map((group) => ({
        label: group.groupDescription,
        id: group.groupId,
        textAlign: 'start' as const,
        width: 'calc(var(--st-unit-20) * 2)',
        isSorted: false,
      })),
      {
        label: 'Action',
        id: 'empty',
        textAlign: 'start' as const,
        width: 'calc(var(--st-unit-20) * 2)',
        isSorted: false,
      },
    ];

    setColumns([...columnData]);
  }, []);

  /* Hooks */
  useEffect(() => {
    setColumnData();
    setRowData();
  }, [setColumnData, setRowData]);

  return (
    <View>
      <View className={styles['permission__list']}>
        <MasterTitle title={t('Sidenav.Permissions')}>
          <View direction="row" justify="end" align="center" gap={4}>
            <View.Item>
              <View direction="row" gap={4}>
                <View.Item>
                  <Button
                    size="large"
                    onClick={() => navigate(PAGE_URLS.MANAGE_SUBGROUP_PERMISSIONS)}
                  >
                    <View direction="row" align="center" justify="center" gap={2}>
                      <Text>{t('AssignPermission')}</Text>
                    </View>
                  </Button>
                </View.Item>
              </View>
            </View.Item>
          </View>
        </MasterTitle>

        <View padding={6} backgroundColor="secondary">
          <View gap={3}>
            <View.Item columns={{ s: 12, l: 3 }}>
              <FormLabel text={t('CreateRoleModal.RoleName')} />
              <TextField
                attributes={{
                  'data-testid': 'name',
                }}
                inputAttributes={{
                  placeholder: t('CreateRoleModal.RoleNamePlaceholder'),
                  onChange: (e) => setSearchQuery(e.target.value),
                }}
                defaultValue=""
              />
            </View.Item>
          </View>
        </View>

        <View padding={[4, 6]}>
          <Table
            columns={columns}
            rows={mapGroupPermissionTableRows(rows, CAPABILITY_GROUP_ROWS)}
            isPaginated={true}
            isCheckboxDisabled={false}
            pageSize={PAGE_SIZE}
            defaultPage={DEFAULT_PAGE}
            isCreditItem={false}
            isCheckboxTable={false}
            styleVariant={TableStylingVariants.DEFAULT}
            totalPages={Math.ceil((rows.length || DEFAULT_PAGE - 1) / PAGE_SIZE)}
            onSort={() => ({})}
          />
        </View>
      </View>
    </View>
  );
};
