/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';

import { Button, FormControl, Icon, Modal, Text, TextArea, View, classNames } from '@az/starc-ui';
import { Close } from '@az/starc-ui-icons';

import { TextAreaCounter } from '@shared/components/TextAreaCounter/TextAreaCounter';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import { NOTIFICATION_TYPES } from '@shared/constants/constants';
import { PAGE_URLS } from '@shared/constants/routes';

import { formatErrorLog, getFormInputError, isButtonDisabled } from '@ofm/utils/utils';
import { RevertPOStatusReasonType } from '@inventory/types/types';
import { LogError } from '@ofm/classes/LogError';
import { ErrorLogType } from '@ofm/constants/constants';

import { useUpdateTrailerArrival } from '@inbound/services/hooks/useUpdateTrailerArrival';
import { INBOUND_ORDER_STATUS, POActions } from '@inbound/constants/constants';

import { MAX_TEXTAREA_LENGTH_REASON_FOR_REVERT_PO_STATUS } from '@inventory/constants/constants';
import { ReasonSchema } from '@inventory/schemas/sendToInbountModalSchema';

import * as T from './RevertPOStatus.types';
import s from './RevertPOStatus.module.scss';

export const RevertPOStatus = ({ isOpen, onClose, trailerOrder, sourceOrderId }: T.Props) => {
  /* Constants */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleNotification } = useNotificationHandler();

  const {
    reset,
    watch,
    register,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<RevertPOStatusReasonType>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: zodResolver(ReasonSchema),
  });

  /* Queries */
  const { isLoading: trailerArrivalLoading, mutateUpdateTrailerArrival } =
    useUpdateTrailerArrival();

  /* Functions */
  const onCloseModal = () => {
    reset();
    clearErrors();
    onClose();
  };

  const onSubmit = (data: RevertPOStatusReasonType) => {
    const revertPOStatusResult = ReasonSchema.safeParse(data);

    if (!revertPOStatusResult.success) {
      throw new LogError(formatErrorLog(ErrorLogType.ZOD, revertPOStatusResult.error));
    } else {
      mutateUpdateTrailerArrival(
        {
          receiptId: trailerOrder?.receiptId || '',
          operation: POActions.EDIT_DETAILS,
          trailerArrivalUpdateRequest: {
            trailerLocationId: trailerOrder?.trailerArrival?.trailerLocationId || '',
            trailerLocationTypeCd: trailerOrder?.trailerArrival?.trailerLocationTypeCd || '',
            trailerOrders: [
              {
                trailerOrderKey: trailerOrder?.trailerOrderKey || '',
                commodityTypeCd: trailerOrder?.commodityTypeCd || '',
                inboundOrder: {
                  statusCd: INBOUND_ORDER_STATUS.RECEIVING_IN_PROGRESS,
                  statusRevertReason: revertPOStatusResult.data.reason,
                },
              },
            ],
            contactName: null,
            contactPhone: null,
            contactEmail: null,
          },
        },
        {
          onSuccess: () => {
            onCloseModal();
            navigate(PAGE_URLS.FINALIZATION_DASHBOARD);
            handleNotification(
              NOTIFICATION_TYPES.SUCCESS,
              t('FinalizationDashboard.Notification.RevertPOStatus.Success', {
                poNumber: sourceOrderId,
              })
            );
          },
        }
      );
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => onCloseModal()}
      className={s['revert-po-status-modal']}
      closeByClickAway={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <View direction="column" className={s['revert-po-status-modal__form-wrapper']}>
          <View className={s['revert-po-status-modal__header']}>
            <View direction="column">
              <View padding={[0, 0, 2, 0]}>
                <Text as="h2" size="175" weight="bold" color="primary">
                  {t('FinalizationDashboard.RevertPOStatus.Confirmation')}
                </Text>
              </View>
              <Text weight="medium">{t('FinalizationDashboard.RevertPOStatus.Message')}</Text>
            </View>
            <Button
              variant="ghost"
              onClick={onCloseModal}
              className={classNames(
                s['close-icon'],
                s['revert-po-status-modal__header__close-button']
              )}
            >
              <Icon svg={Close} />
            </Button>
          </View>

          <View direction="column" className={s['revert-po-status-modal__body']}>
            <View.Item>
              <View direction="column" className={s['revert-po-status-modal__body__section']}>
                <View.Item>
                  <View
                    direction="column"
                    className={s['revert-po-status-modal__body__section__item']}
                  >
                    <Text
                      weight="medium"
                      size="087"
                      className={s['revert-po-status-modal__body__form-field-label--required']}
                    >
                      {t('FinalizationDashboard.RevertPOStatus.CommentLabel')}
                    </Text>

                    <FormControl hasError={!!errors.reason}>
                      <TextArea
                        resize="vertical"
                        id="remove-reason"
                        label=""
                        defaultValue=""
                        inputAttributes={{
                          ...register('reason'),
                          maxLength: MAX_TEXTAREA_LENGTH_REASON_FOR_REVERT_PO_STATUS,
                        }}
                      />

                      <View direction="row" justify={errors.reason ? 'space-between' : 'end'}>
                        {errors.reason && (
                          <FormControl.Error
                            className={s['revert-po-status-modal__body__form-field-error-messages']}
                          >
                            {getFormInputError(
                              errors.reason?.type,
                              MAX_TEXTAREA_LENGTH_REASON_FOR_REVERT_PO_STATUS
                            )}
                          </FormControl.Error>
                        )}

                        <TextAreaCounter
                          count={watch('reason')?.length}
                          maxCount={MAX_TEXTAREA_LENGTH_REASON_FOR_REVERT_PO_STATUS}
                        />
                      </View>
                    </FormControl>
                  </View>
                </View.Item>
              </View>
            </View.Item>
          </View>

          <View className={s['revert-po-status-modal__footer']}>
            <View
              width="100%"
              direction="row"
              justify="end"
              className={s['revert-po-status-modal__footer__actions']}
            >
              <Button
                variant="secondary"
                attributes={{ style: { width: 'fit-content' } }}
                disabled={trailerArrivalLoading}
                onClick={onCloseModal}
              >
                <Text>{t('Cancel')}</Text>
              </Button>

              <Button
                variant="primary"
                attributes={{ style: { width: 'fit-content' } }}
                type="submit"
                loading={trailerArrivalLoading}
                disabled={isButtonDisabled(watch('reason'))}
              >
                {t('FinalizationDashboard.RevertPOStatus.Label')}
              </Button>
            </View>
          </View>
        </View>
      </form>
    </Modal>
  );
};
