/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Button, Dropdown, Text, SearchBar, Info, Skeleton } from '@az/starc-ui';
import { EmptyState } from '@shared/components/EmptyState/EmptyState';
import {
  DEPARTMENTS,
  ENTITY_ASSOCIATIONS,
  NOTIFICATION_TYPES,
  SORT_BY,
  SORT_ORDER,
  TASK_ATTRIBUTES,
  TASK_STATUS,
} from '@shared/constants/constants';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import { KEY, KEY_CODE } from '@shared/constants/keyConstants';
import { MAX_SIZE, PAGE, TASK_TYPE_TAB } from '@taskManagement/constants/constants';
import { CommonTabCard } from '@taskManagement/components/CombinedPillTabs/CommonTabCard';
import { AssignTaskModal } from '@taskManagement/components/AssignTaskModal/AssignTaskModal';
import { TabsFilterDataProps } from './CombinedPillTabs.types';
import styles from './CombinedPillTabs.module.scss';
import { useGetTaskSearchData } from '@shared/services/hooks/useGetTaskSearchData';
import { TaskSearchSchemaType } from '@shared/types/schema.type';

export const CombinedPillTabs = ({
  TabsFilterData,
  userId,
  firstName,
  lastName,
  shiftId,
  usersData,
}: TabsFilterDataProps) => {
  /* state variables */
  const [value, setValue] = useState<string>('');
  const [filteredArray, setFilteredArray] = useState<TaskSearchSchemaType[]>([]);
  const [unAssignedTaskData, setUnAssignedTaskData] = useState<TaskSearchSchemaType[]>([]);
  const [selectedTab, setSelectedTab] = useState(TabsFilterData[0]);
  const [OpenTaskConfirmModal, setOpenTaskConfirmModal] = useState<boolean>(false);
  const [AssignPOData, setAssignPOData] = useState<TaskSearchSchemaType | undefined>(undefined);

  /* Constants */
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  const MaxTabCount = TabsFilterData.length;

  /* Queries */
  const { isFetching: isLoading, taskSearchData } = useGetTaskSearchData({
    searchPage: {
      page: PAGE,
      size: MAX_SIZE,
      sortBy: SORT_BY.PRIORITY,
      direction: SORT_ORDER.DESC,
    },
    searchCriteria: {
      departmentId: DEPARTMENTS.INBOUND,
      taskStatusCd: [
        TASK_STATUS.ASSIGNED,
        TASK_STATUS.INPROGRESS,
        TASK_STATUS.NEW,
        TASK_STATUS.UNASSIGNED,
        TASK_STATUS.INACTIVE,
      ],
      entityAssociations: [
        ENTITY_ASSOCIATIONS.ATTRIBUTES,
        ENTITY_ASSOCIATIONS.ASSIGNMENTS,
        ENTITY_ASSOCIATIONS.ACTIVITIES,
      ],
    },
  });
  /* Functions */
  const onClickAssignCurrentTask = (poData: TaskSearchSchemaType) => {
    setOpenTaskConfirmModal(true);
    setAssignPOData(poData);
  };

  const CloseConfirmationModal = () => {
    setOpenTaskConfirmModal(false);
  };

  const sortByTrailerArrival = () => {
    filteredArray.sort((a, b) => {
      const arrivalTsAAttr = a.attributes.find(
        (attr: { key: string }) => attr.key === 'trailerArrivalTs'
      );
      const arrivalTsBAttr = b.attributes.find(
        (attr: { key: string }) => attr.key === 'trailerArrivalTs'
      );
      const priorityA = a.priority ?? 0;
      const priorityB = b.priority ?? 0;
      if (priorityA !== priorityB) {
        return priorityB - priorityA;
      }
      if (arrivalTsAAttr && arrivalTsBAttr) {
        const arrivalTsA = new Date(arrivalTsAAttr.value).getTime();
        const arrivalTsB = new Date(arrivalTsBAttr.value).getTime();
        return arrivalTsA - arrivalTsB;
      }
      if (arrivalTsAAttr && !arrivalTsBAttr) {
        return -1;
      }
      if (!arrivalTsAAttr && arrivalTsBAttr) {
        return 1;
      }
      return 0;
    });
  };

  const handleItemSearch = (search: string) => {
    setValue(search);
    // Filter TASK_SEARCH_MOCK_Data based on PO number or vendor name
    const filteredArray =
      unAssignedTaskData.filter((data) =>
        data.attributes.some(
          (attr) =>
            (attr.key === TASK_ATTRIBUTES.SOURCE_ORDER_NBR && attr.value.includes(search)) ||
            (attr.key === TASK_ATTRIBUTES.VENDOR_NAME &&
              attr.value.toLowerCase().includes(search.toLowerCase()))
        )
      ) ?? [];
    setFilteredArray(filteredArray);
  };

  const onClearSearch = () => {
    setValue('');
    setFilteredArray(unAssignedTaskData ?? []);
  };

  const onKeyDown = (e: {
    key: string;
    keyCode: number;
    preventDefault: () => void;
    currentTarget: { value: string };
  }) => {
    if (e.key === KEY.ENTER || e.keyCode === KEY_CODE.ENTER) {
      e.preventDefault();
      const inputValue = (e.currentTarget as HTMLInputElement).value;
      handleItemSearch(inputValue);
    }
  };

  const onChange = (e: { target: { value: string } }) => {
    const inputValue = e.target.value;
    if (!inputValue) {
      onClearSearch();
    }
  };

  const onShowNotification = () => {
    setOpenTaskConfirmModal(false);
    handleNotification(
      NOTIFICATION_TYPES.SUCCESS,
      t(`AssignTaskConfirmationModal.OnSuccess`, {
        userName: `${firstName} ${lastName}`,
      })
    );
  };
  /* Hooks */
  useEffect(() => {
    sortByTrailerArrival();
  });

  useEffect(() => {
    if (taskSearchData?.content) {
      const filteredTasks = taskSearchData.content.filter((task) => {
        return task.assignments?.every((assignment) => assignment.userId !== userId);
      });
      setFilteredArray(filteredTasks);
      setUnAssignedTaskData(filteredTasks);
    }
  }, [taskSearchData, userId]);
  return (
    <>
      <View
        gap={2}
        direction="row"
        align="center"
        attributes={{ 'data-testid': 'st-combined-pill-tabs' }}
      >
        {TabsFilterData.slice(0, 3).map((filter) => (
          <>
            <View.Item>
              <Button
                className={styles['combined-pill-tabs__button']}
                onClick={() => setSelectedTab(filter)}
                variant={selectedTab.value === filter.value ? 'pill' : 'pill-secondary'}
                attributes={{ style: { width: 'fit-content' } }}
              >
                {t(filter.label)}
              </Button>
            </View.Item>
          </>
        ))}
        <View.Item>
          <Dropdown className={styles['combined-pill-tabs__dropdown']}>
            {selectedTab.value === TASK_TYPE_TAB.OUTBOUND ||
            selectedTab.value === TASK_TYPE_TAB.INVENTORY_CONTROL ||
            selectedTab.value === TASK_TYPE_TAB.GENERAL_TASKS ? (
              <Dropdown.Button
                className={[
                  styles['combined-pill-tabs__dropdown-button'],
                  selectedTab.value ? styles['combined-pill-tabs__selected_button'] : '',
                ]}
              >
                <View align="center" gap={0} justify="center">
                  {selectedTab.value}
                </View>
              </Dropdown.Button>
            ) : (
              <Dropdown.Button className={[styles['combined-pill-tabs__dropdown-button']]}>
                <View align="center" gap={0}>
                  {t('MoreOptions')}
                </View>
              </Dropdown.Button>
            )}
            <Dropdown.Content className={styles['combined-pill-tabs__dropdown-content']}>
              {TabsFilterData.slice(3, MaxTabCount).map((filter) => (
                <Button
                  className={styles['combined-pill-tabs__content-button']}
                  onClick={() => setSelectedTab(filter)}
                  variant={selectedTab.value === filter.value ? 'pill' : 'pill-secondary'}
                  attributes={{ style: { width: 'fit-content' } }}
                >
                  {t(filter.label)}
                </Button>
              ))}
            </Dropdown.Content>
          </Dropdown>
        </View.Item>
      </View>
      {selectedTab.value === TASK_TYPE_TAB.POS ||
      selectedTab.value === TASK_TYPE_TAB.REPLENISHMENTS ? (
        <SearchBar
          className={styles['combined-pill-tabs__search']}
          label={t(`SearchDrawerPlaceholder`)}
          maxMenuHeight={300}
          onValueChange={handleItemSearch}
          onValueClear={onClearSearch}
          inputAttributes={{ onKeyDown: onKeyDown, onChange: onChange }}
          value={value}
        />
      ) : (
        ''
      )}
      <View gap={2} align="start" width="100%" className={styles['combined-tabs-direction']}>
        {selectedTab.value === TASK_TYPE_TAB.POS && (
          <View direction="column" gap={2}>
            {isLoading ? (
              <Skeleton
                borderRadius="small"
                width="calc(var(--st-unit-32) * 4 + var(--st-unit-2))"
                height="var(--st-unit-32)"
              />
            ) : filteredArray.length > 0 ? (
              filteredArray
                .sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0))
                .map((poData: TaskSearchSchemaType) => (
                  <CommonTabCard
                    key={poData.taskNo}
                    poData={poData}
                    onClickAssignCurrentTask={onClickAssignCurrentTask}
                    usersData={usersData}
                  />
                ))
            ) : (
              <EmptyState
                svg={Info}
                subtitle={t('Empty.Search.Subtitle')}
                text={t('Empty.Search.Text', {
                  value: value,
                })}
              />
            )}
          </View>
        )}
        {selectedTab.value === TASK_TYPE_TAB.RECOMMENDED && (
          <View direction="column" gap={2}>
            {isLoading ? (
              <Skeleton
                borderRadius="small"
                width="calc(var(--st-unit-32) * 4 + var(--st-unit-2))"
                height="var(--st-unit-32)"
              />
            ) : (
              filteredArray.length > 0 &&
              (filteredArray || [])
                .sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0))
                .slice(0, 3)
                .map((poData: TaskSearchSchemaType) => (
                  <CommonTabCard
                    key={poData.taskNo}
                    poData={poData}
                    onClickAssignCurrentTask={onClickAssignCurrentTask}
                    usersData={usersData}
                  />
                ))
            )}
          </View>
        )}
        {selectedTab.value === TASK_TYPE_TAB.REPLENISHMENTS && (
          <>
            <View padding={0} direction="row">
              <Text>{selectedTab.value}</Text>
            </View>
          </>
        )}

        {selectedTab.value === TASK_TYPE_TAB.GENERAL_TASKS && (
          <>
            <View gap={2} direction="row" align="start">
              <Text size="112" variant="display-4">
                {t('GeneralTask.AssignGeneralTask')}
              </Text>
              <Text> {t('GeneralTask.Subtitle')}</Text>
            </View>
            <View width="100%" direction="row" align="center" justify="center">
              <Button
                fullWidth={true}
                className={styles['combined-pill-tabs__general_button']}
                variant="secondary"
              >
                {t('GeneralTask.CreateGeneralTask')}
              </Button>
            </View>
          </>
        )}
      </View>
      {/* Open task assign confirmation modal popup */}
      {OpenTaskConfirmModal && (
        <AssignTaskModal
          isOpen={OpenTaskConfirmModal}
          onClose={CloseConfirmationModal}
          POModalData={AssignPOData}
          onSuccess={() => onShowNotification()}
          userId={userId}
          firstName={firstName ? firstName : ''}
          lastName={lastName ? lastName : ''}
          shiftId={shiftId}
        />
      )}
    </>
  );
};
