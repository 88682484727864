/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, View, Text, Actionable } from '@az/starc-ui';

import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle';
import { PAGE_URLS } from '@shared/constants/routes';
import { EMPTY_VALUE } from '@shared/constants/constants';
import { Tag } from '@shared/components/Tag/Tag';

import { Tabs } from '@mdm/components/Tabs/Tabs';
import { DOMAIN_TYPE_CD, ORDERS_LINES, TRAILER_ORDERS } from '@inbound/constants/constants';
import { useGetInboundOrder } from '@inbound/services/hooks/useGetInboundOrder';
import { PriorityTag } from '@inbound/components/PriorityTag/PriorityTag';

import { InboundOrderDataType } from '@inventory/types/types';
import { SendToInboundModal } from '@inventory/components/SendToInboundModal/SendToInboundModal';

import { UnCheckedDiscrepancy } from './UnCheckedDiscrepancy';
import { CheckedDiscrepancy } from './CheckedDiscrepancy';

import styles from './PODiscrepancy.module.scss';
import { ActionDropdownMenu } from '@shared/components/ActionDropdownMenu/ActionDropdownMenu';
import { RevertPOStatus } from '@inventory/components/RevertPOStatus';

export const POdiscrepancy = () => {
  /*States*/
  const [showSendToInboundMOdal, setShowSendToInboundMOdal] = useState(false);
  const [showRevertPOStatusModal, setShowRevertPOStatusModal] = useState(false);
  const [isAllDiscrepsChecked, setIsAllDiscrepsChecked] = useState(false);
  const [isEditOrderLinesCount, setIsEditOrderLinesCount] = useState<number>(0);

  /* Constants */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sourceOrderId } = useParams();

  const breadcrumbs = {
    data: [
      {
        label: t('FinalizationDashboard.Breadcrumb.FinalizationDashboard'),
        onClick: () => navigate(PAGE_URLS.FINALIZATION_DASHBOARD),
      },
      {
        label: t('FinalizationDashboard.Breadcrumb.PO', { poNumber: sourceOrderId }),
        onClick: () => navigate(''),
      },
    ],
  };

  /* Queries */
  const {
    inboundOrderData,
    isLoading: isOrderLoading,
    isFetching,
  } = useGetInboundOrder({
    searchCriteria: {
      inboundOrder: {
        domainTypeCd: DOMAIN_TYPE_CD,
        sourceOrderNbr: sourceOrderId,
        entityAssociations: [ORDERS_LINES, TRAILER_ORDERS],
      },
    },
  });

  const tabs = [
    {
      value: t('FinalizationDashboard.Tabs.Unchhecked'),
      name: t('FinalizationDashboard.Tabs.Unchhecked'),
      content: (
        <UnCheckedDiscrepancy
          inboundOrderData={inboundOrderData?.content[0]}
          isOrderLoading={isOrderLoading}
          isInboundOrderFeatching={isFetching}
          setIsAllDiscrepsChecked={setIsAllDiscrepsChecked}
        />
      ),
    },
    {
      value: t('FinalizationDashboard.Tabs.Checked'),
      name: t('FinalizationDashboard.Tabs.Checked'),
      content: (
        <CheckedDiscrepancy
          inboundOrderData={inboundOrderData?.content[0]}
          isOrderLoading={isOrderLoading}
          isInboundOrderFeatching={isFetching}
          setIsEditOrderLinesCount={setIsEditOrderLinesCount}
        />
      ),
    },
  ];

  const renderMaterTitleSubTitle = (inboundOrderData: InboundOrderDataType | undefined) => {
    const commodityTypeCd =
      inboundOrderData?.inboundOrder?.trailerOrders.slice(-1)[0]?.commodityTypeCd;

    return (
      <View align="start">
        <View.Item>
          <View
            direction="row"
            align="center"
            justify="center"
            className={styles['po-discrepancy__master-title__sub-title']}
          >
            <View.Item>
              <View
                direction="row"
                align="center"
                justify="center"
                className={styles['po-discrepancy__master-title__sub-title__right-items']}
              >
                {inboundOrderData?.inboundOrder?.priority && (
                  <View.Item>
                    <PriorityTag />
                  </View.Item>
                )}

                {inboundOrderData?.inboundOrder?.orderTypeCd && (
                  <View.Item>
                    <View justify="center">
                      <Tag
                        variant="order"
                        text={inboundOrderData?.inboundOrder.orderTypeCd}
                        className={styles['po-discrepancy__master-title__sub-title__tag']}
                      />
                    </View>
                  </View.Item>
                )}
                {commodityTypeCd && (
                  <View.Item>
                    <View justify="center">
                      <Tag
                        variant="order"
                        text={commodityTypeCd}
                        className={styles['po-discrepancy__master-title__sub-title__tag']}
                      />
                    </View>
                  </View.Item>
                )}
              </View>
            </View.Item>

            <View.Item>
              <Text color="600">
                {t('PODashboard.PoWithNumber', {
                  poNumber: inboundOrderData?.inboundOrder?.sourceOrderNbr,
                })}
              </Text>
            </View.Item>

            {inboundOrderData?.inboundOrder?.orderLocationId && (
              <View.Item>
                <Text color="600">
                  {t('PODashboard.DoorWithName', {
                    door: inboundOrderData.inboundOrder.orderLocationId,
                  })}
                </Text>
              </View.Item>
            )}
          </View>
        </View.Item>
      </View>
    );
  };

  return (
    <View>
      <MasterTitle
        title={inboundOrderData?.content[0]?.inboundOrder.vendorLocale?.vendorName || EMPTY_VALUE}
        breadcrumbProps={breadcrumbs}
        subtitle={renderMaterTitleSubTitle(inboundOrderData?.content[0])}
      >
        <View direction="row" justify="end" align="center" gap={4}>
          <View.Item>
            <Button
              size="large"
              onClick={() => setShowSendToInboundMOdal(true)}
              disabled={!isAllDiscrepsChecked || isEditOrderLinesCount !== 0}
            >
              <View direction="row">
                <Text>{t('FinalizationDashboard.SendToInbound.Label')}</Text>
              </View>
            </Button>
          </View.Item>
          <View.Item>
            <ActionDropdownMenu>
              <View padding={[1, 0]}>
                <View.Item>
                  <View padding={[3, 4]}>
                    <Actionable fullWidth onClick={() => setShowRevertPOStatusModal(true)}>
                      <Text>{t('FinalizationDashboard.RevertPOStatus.Label')}</Text>
                    </Actionable>
                  </View>
                </View.Item>
              </View>
            </ActionDropdownMenu>
          </View.Item>
        </View>
      </MasterTitle>
      <Tabs tabs={tabs} />
      {showSendToInboundMOdal && (
        <SendToInboundModal
          isOpen={showSendToInboundMOdal}
          onClose={() => setShowSendToInboundMOdal(false)}
          trailerOrder={inboundOrderData?.content[0]?.inboundOrder.trailerOrders.slice(-1)[0]}
          sourceOrderId={sourceOrderId ?? ''}
        />
      )}
      {showRevertPOStatusModal && (
        <RevertPOStatus
          isOpen={showRevertPOStatusModal}
          onClose={() => setShowRevertPOStatusModal(false)}
          trailerOrder={inboundOrderData?.content[0]?.inboundOrder.trailerOrders.slice(-1)[0]}
          sourceOrderId={sourceOrderId ?? ''}
        />
      )}
    </View>
  );
};
