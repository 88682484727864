/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Button, Checkbox, FormControl, Select, Text, TextField, View } from '@az/starc-ui';
import { useTranslation } from 'react-i18next';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle.tsx';
import { PAGE_URLS } from '@shared/constants/routes.ts';

import { useNavigate, useParams } from 'react-router-dom';

import { useSessionStorage } from '@shared/hooks/useStorage';
import { SESSION_DC_ID_KEY } from '@shared/constants/storageConstants';
import { z } from 'zod';

import styles from './OrderTypeDetail.module.scss';
import { useBreadcrumb } from '@mdm/hooks/useBreadcrumb.ts';
import { getFormDefaults, getFormFieldIndexByKey } from '@mdm/utils/form/formUtils.tsx';

import {
  DEFAULT_DETAIL_VIEW_LOADING_COUNT,
  MAX_LENGTH_FIELD,
  ORDER_TYPE_FIELD,
} from '@mdm/constants/constants.ts';
import { DetailsSectionSkeleton } from '@shared/components/Skeletons/DetailsSectionSkeleton.tsx';
import { FormLabel } from '@shared/components/FormLabel/FormLabel.tsx';
import { OrderTypeViewSchema, defaultAttributeValues } from '@mdm/schemas/orderTypeSchema.ts';
import { displayAttributeFieldValidationMessage } from '@mdm/utils/form/validationUtils.tsx';
import { useMutateOrderType } from '@mdm/services/hooks/useMutateOrderType.ts';
import { NOTIFICATION_TYPES } from '@shared/constants/constants.ts';
import { useQueryClient } from '@tanstack/react-query';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { useGetOrderTypes } from '@mdm/services/hooks/useGetOrderTypes.tsx';
import { useEffect, useState } from 'react';
import { AttributeType } from '@mdm/types/schema.type.ts';

export const OrderTypeDetail = () => {
  /* Constants */
  type FormData = z.infer<typeof OrderTypeViewSchema>;
  const { orderTypeCode: orderTypeId } = useParams();
  const { mutateOrderType, isLoading: isOrderTypeUpdating } = useMutateOrderType();
  const queryClient = useQueryClient();
  const { handleNotification } = useNotificationHandler();
  const [isDataLoaded, setIsDataLoaded] = useState(!orderTypeId);

  const { orderTypesData } = useGetOrderTypes(
    {
      orderTypeCodes: orderTypeId ? orderTypeId : '',
    },
    !!orderTypeId
  );

  const {
    control,
    register,
    watch,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    reset: orderTypeReset,
    formState: { errors },
  } = useForm<FormData>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    defaultValues: getFormDefaults(OrderTypeViewSchema),
    resolver: zodResolver(OrderTypeViewSchema),
  });

  const { fields: attributeFields } = useFieldArray({
    control,
    name: 'attributes',
  });

  const orderTypeCode = watch('orderTypeCode');

  /* Function */
  const onSubmit = () => {
    const formData = getValues();
    mutateOrderType(
      {
        orderTypeCode: orderTypeId || '',
        orderTypeRequest: {
          orderTypeCode: formData.orderTypeCode || '',
          description: formData.description || '',
          attributes: formData.attributes
            .filter((attribute) => attribute.value !== '')
            .map((attribute) => {
              return {
                ...attribute,
                value: attribute.value.toString(),
                valueIndex: 1,
              };
            }),
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['orderTypes']);
          let successMessage = t('Success.Action.OrderType.Created');
          if (orderTypeId) {
            successMessage = t('Success.Action.OrderType.Updated', {
              orderTypeCode: formData.orderTypeCode,
            });
          }
          handleNotification(NOTIFICATION_TYPES.SUCCESS, successMessage);
          navigate(PAGE_URLS.ORDER_TYPE_DETAILS(formData.orderTypeCode));
        },
      }
    );
  };

  const revalidateAttributeField = (attributeName: string) => {
    const attributeIndex = attributeFields.findIndex((field) => field.name === attributeName);
    if (attributeIndex !== -1) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      trigger(`attributes[attribute-${attributeIndex}]`);
    }
  };

  /* Hooks */
  const [selectedDC] = useSessionStorage<string>(SESSION_DC_ID_KEY);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const breadcrumbs = useBreadcrumb(orderTypeId ? PAGE_URLS.ORDER_TYPE_CREATE : '');

  useEffect(() => {
    if (orderTypeId && orderTypesData && orderTypesData.length > 0) {
      let attributes = orderTypesData[0].attributes || [];
      for (const defaultAttributeValue of defaultAttributeValues) {
        if (!attributes.find((a: AttributeType) => a.name === defaultAttributeValue.name)) {
          attributes.push(defaultAttributeValue);
        }
      }

      attributes = attributes.filter((attribute: AttributeType) =>
        defaultAttributeValues.find(
          (defaultAttributeValue) => attribute.name === defaultAttributeValue.name
        )
      );

      orderTypeReset({
        orderTypeCode: orderTypesData[0].orderTypeCode,
        description: orderTypesData[0].description,
        attributes,
      });

      setIsDataLoaded(true);
    } else if (orderTypeId && orderTypesData && orderTypesData.length === 0) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('OrderTypeNotFound'));
    }
  }, [handleNotification, orderTypeId, orderTypeReset, orderTypesData, t]);

  if (!isDataLoaded) {
    return <DetailsSectionSkeleton items={DEFAULT_DETAIL_VIEW_LOADING_COUNT} />;
  } else {
    return (
      <>
        <View
          className={styles['order-type-detail']}
          backgroundColor="secondary"
          direction="column"
          height="100%"
        >
          <View.Item
            attributes={{
              'data-testid': 'order-type-details-header',
            }}
          >
            <MasterTitle
              title={`${t('OrderTypeDetails.Heading')}: ${
                orderTypeCode ? orderTypeCode : t('Untitled')
              }`}
              breadcrumbProps={breadcrumbs}
            >
              <View
                attributes={{
                  'data-testid': 'order-type-details-action',
                }}
                direction="row"
                justify="end"
                align="center"
                gap={4}
              >
                <View.Item>
                  <View direction="row" gap={4}>
                    <View.Item>
                      <Button
                        variant="secondary"
                        size="large"
                        onClick={() => navigate(PAGE_URLS.ORDER_TYPE_LIST)}
                      >
                        <View direction="row" align="center" justify="center" gap={2}>
                          <Text>{t('Cancel')}</Text>
                        </View>
                      </Button>
                    </View.Item>
                  </View>
                </View.Item>
                <View.Item>
                  <View direction="row" gap={4}>
                    <View.Item>
                      <Button
                        size="large"
                        loading={isOrderTypeUpdating}
                        onClick={() => handleSubmit(onSubmit)()}
                      >
                        <View direction="row" align="center" justify="center" gap={2}>
                          <Text>{t('SaveEdits')}</Text>
                        </View>
                      </Button>
                    </View.Item>
                  </View>
                </View.Item>
              </View>
            </MasterTitle>
          </View.Item>
          <View backgroundColor="secondary" padding={6} height="100%">
            <View>
              <View.Item>
                <Text size="125" weight="bold">
                  {t('OrderTypeDetails.Title')}
                </Text>
              </View.Item>
            </View>
            <View className={styles['order-type-detail__content-section']}>
              <View>
                <form className="order-type-detail__form">
                  <View direction="row" gap={4}>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <Text
                          className={styles['order-type-detail__form-field-label--required']}
                          weight="medium"
                          size="087"
                        >
                          {t('Warehouse')}
                        </Text>
                        <FormControl>
                          <Select
                            attributes={{
                              'data-testid': 'warehouse-id',
                              style: { width: 'var(--st-unit-5)' },
                            }}
                            label={t('Warehouse')}
                            name="warehouse"
                            variant="no-label"
                            multiSelect={false}
                            defaultValue={{
                              label: 'DC ' + selectedDC?.toString(),
                              value: 'DC ' + selectedDC?.toString(),
                            }}
                            options={[]}
                            disabled
                          />
                        </FormControl>
                        <Text weight="regular" color="600" size="087">
                          {t('OrderTypeDetails.WarehouseInstruction')}
                        </Text>
                      </View>
                    </View.Item>
                  </View>
                  <View className={[styles['form__field-row']]} direction="row" gap={4}>
                    <View.Item>
                      <View gap={2}>
                        <FormLabel text={t('OrderTypeDetails.Type.Label')} isRequired={true} />
                        <FormControl hasError={!!errors.orderTypeCode}>
                          <TextField
                            defaultValue=""
                            disabled={!!orderTypeId}
                            attributes={{
                              'data-testid': 'type',
                            }}
                            inputAttributes={{
                              maxLength: MAX_LENGTH_FIELD.ORDER_TYPE_FIELD,
                              ...register('orderTypeCode'),
                            }}
                          />

                          {errors.orderTypeCode && (
                            <View direction="row" justify="space-between">
                              {errors.orderTypeCode && (
                                <FormControl.Error>
                                  {errors.orderTypeCode.message}
                                </FormControl.Error>
                              )}
                            </View>
                          )}
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item>
                      <View gap={2}>
                        <FormLabel
                          text={t('OrderTypeDetails.Description.Label')}
                          isRequired={true}
                        />
                        <FormControl hasError={!!errors.description}>
                          <TextField
                            defaultValue=""
                            attributes={{
                              'data-testid': 'description',
                            }}
                            inputAttributes={{
                              maxLength: MAX_LENGTH_FIELD.ORDER_TYPE_DESCRIPTION,
                              ...register('description'),
                            }}
                          />

                          {errors.description && (
                            <View direction="row" justify="space-between">
                              {errors.description && (
                                <FormControl.Error>{errors.description.message}</FormControl.Error>
                              )}
                            </View>
                          )}
                        </FormControl>
                      </View>
                    </View.Item>
                  </View>
                  <View className={[styles['form__field-row']]} direction="row" gap={4}>
                    <View.Item>
                      <View gap={2}>
                        <FormLabel text={t('OrderTypeDetails.Priority.Label')} isRequired={true} />
                        <FormControl
                          hasError={
                            !!(
                              errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  ORDER_TYPE_FIELD.PRIORITY
                                )
                              )
                            )
                          }
                        >
                          <TextField
                            defaultValue=""
                            attributes={{
                              'data-testid': 'priority',
                            }}
                            inputAttributes={{
                              maxLength: MAX_LENGTH_FIELD.ORDER_TYPE_PRIORITY,
                              ...register(
                                `attributes.${getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  ORDER_TYPE_FIELD.PRIORITY
                                )}.value`
                              ),
                              onChange: (e) => {
                                setValue(
                                  `attributes.${getFormFieldIndexByKey(
                                    getValues()['attributes'],
                                    ORDER_TYPE_FIELD.PRIORITY
                                  )}.value`,
                                  e.target.value
                                );
                                revalidateAttributeField(ORDER_TYPE_FIELD.PRIORITY);
                              },
                            }}
                          />

                          {errors.attributes &&
                            displayAttributeFieldValidationMessage(
                              errors,
                              getFormFieldIndexByKey(
                                getValues()['attributes'],
                                ORDER_TYPE_FIELD.PRIORITY
                              )
                            ) && (
                              <View direction="row" justify="space-between">
                                <FormControl.Error>
                                  {displayAttributeFieldValidationMessage(
                                    errors,
                                    getFormFieldIndexByKey(
                                      getValues()['attributes'],
                                      ORDER_TYPE_FIELD.PRIORITY
                                    )
                                  )}
                                </FormControl.Error>
                              </View>
                            )}
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item>
                      <View gap={2}>
                        <FormLabel
                          text={`${t('OrderTypeDetails.PalletThreshold.Label')} (${t('Optional')})`}
                        />
                        <FormControl
                          hasError={
                            !!(
                              errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  ORDER_TYPE_FIELD.PALLET_THRESHOLD
                                )
                              )
                            )
                          }
                        >
                          <TextField
                            defaultValue=""
                            attributes={{
                              'data-testid': 'palletThreshold',
                            }}
                            inputAttributes={{
                              maxLength: MAX_LENGTH_FIELD.ORDER_TYPE_PALLET_THRESHOLD,
                              ...register(
                                `attributes.${getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  ORDER_TYPE_FIELD.PALLET_THRESHOLD
                                )}.value`
                              ),
                              onChange: (e) => {
                                setValue(
                                  `attributes.${getFormFieldIndexByKey(
                                    getValues()['attributes'],
                                    ORDER_TYPE_FIELD.PALLET_THRESHOLD
                                  )}.value`,
                                  e.target.value
                                );
                                revalidateAttributeField(ORDER_TYPE_FIELD.PALLET_THRESHOLD);
                              },
                            }}
                          />

                          {errors.attributes &&
                            displayAttributeFieldValidationMessage(
                              errors,
                              getFormFieldIndexByKey(
                                getValues()['attributes'],
                                ORDER_TYPE_FIELD.PALLET_THRESHOLD
                              )
                            ) && (
                              <View direction="row" justify="space-between">
                                <FormControl.Error>
                                  {displayAttributeFieldValidationMessage(
                                    errors,
                                    getFormFieldIndexByKey(
                                      getValues()['attributes'],
                                      ORDER_TYPE_FIELD.PALLET_THRESHOLD
                                    )
                                  )}
                                </FormControl.Error>
                              </View>
                            )}
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item>
                      <View gap={2}>
                        <FormLabel text={`${t('OrderTypeDetails.ShuttlePriority.Label')}`} />
                        <FormControl
                          hasError={
                            !!(
                              errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  ORDER_TYPE_FIELD.SHUTTLE_PRIORITY
                                )
                              )
                            )
                          }
                        >
                          <TextField
                            defaultValue=""
                            attributes={{
                              'data-testid': 'shuttlePriority',
                            }}
                            inputAttributes={{
                              maxLength: MAX_LENGTH_FIELD.ORDER_TYPE_SHUTTLE_PRIORITY,
                              ...register(
                                `attributes.${getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  ORDER_TYPE_FIELD.SHUTTLE_PRIORITY
                                )}.value`
                              ),
                              onChange: (e) => {
                                setValue(
                                  `attributes.${getFormFieldIndexByKey(
                                    getValues()['attributes'],
                                    ORDER_TYPE_FIELD.SHUTTLE_PRIORITY
                                  )}.value`,
                                  e.target.value
                                );
                                revalidateAttributeField(ORDER_TYPE_FIELD.SHUTTLE_PRIORITY);
                              },
                            }}
                          />

                          {errors.attributes &&
                            displayAttributeFieldValidationMessage(
                              errors,
                              getFormFieldIndexByKey(
                                getValues()['attributes'],
                                ORDER_TYPE_FIELD.SHUTTLE_PRIORITY
                              )
                            ) && (
                              <View direction="row" justify="space-between">
                                <FormControl.Error>
                                  {displayAttributeFieldValidationMessage(
                                    errors,
                                    getFormFieldIndexByKey(
                                      getValues()['attributes'],
                                      ORDER_TYPE_FIELD.SHUTTLE_PRIORITY
                                    )
                                  )}
                                </FormControl.Error>
                              </View>
                            )}
                        </FormControl>
                      </View>
                    </View.Item>
                  </View>
                  <View className={[styles['form__field-row']]} direction="row" gap={4}>
                    <View.Item>
                      <FormLabel text={t('OrderTypeDetails.ReleaseOptions.Label')} />
                    </View.Item>
                  </View>
                  <View direction="row" gap={4}>
                    <View.Item>
                      <FormControl
                        hasError={
                          !!(
                            errors.attributes &&
                            displayAttributeFieldValidationMessage(
                              errors,
                              getFormFieldIndexByKey(
                                getValues()['attributes'],
                                ORDER_TYPE_FIELD.RELEASE_TO_LIGHT
                              )
                            )
                          )
                        }
                      >
                        <Controller
                          control={control}
                          name={`attributes.${getFormFieldIndexByKey(
                            getValues()['attributes'],
                            ORDER_TYPE_FIELD.RELEASE_TO_LIGHT
                          )}.value`}
                          render={({ field: { onChange, value, ref } }) => (
                            <Checkbox
                              name="releaseToLight"
                              value={value}
                              checked={value && value.toString() === 'true'}
                              inputAttributes={{ ref }}
                              label={t('OrderTypeDetails.ReleaseToPickToLight.Label')}
                              onChange={(event) => onChange(event)}
                            />
                          )}
                        />
                        {errors.attributes &&
                          displayAttributeFieldValidationMessage(
                            errors,
                            getFormFieldIndexByKey(
                              getValues()['attributes'],
                              ORDER_TYPE_FIELD.RELEASE_TO_LIGHT
                            )
                          ) && (
                            <View direction="row" justify="space-between">
                              <FormControl.Error>
                                {displayAttributeFieldValidationMessage(
                                  errors,
                                  getFormFieldIndexByKey(
                                    getValues()['attributes'],
                                    ORDER_TYPE_FIELD.RELEASE_TO_LIGHT
                                  )
                                )}
                              </FormControl.Error>
                            </View>
                          )}
                      </FormControl>
                    </View.Item>
                    <View.Item>
                      <FormControl
                        hasError={
                          !!(
                            errors.attributes &&
                            displayAttributeFieldValidationMessage(
                              errors,
                              getFormFieldIndexByKey(
                                getValues()['attributes'],
                                ORDER_TYPE_FIELD.RELEASE_TO_SHUTTLE
                              )
                            )
                          )
                        }
                      >
                        <Controller
                          control={control}
                          name={`attributes.${getFormFieldIndexByKey(
                            getValues()['attributes'],
                            ORDER_TYPE_FIELD.RELEASE_TO_SHUTTLE
                          )}.value`}
                          render={({ field: { onChange, value, ref } }) => (
                            <Checkbox
                              name="releaseToShuttle"
                              value={value}
                              checked={value && value.toString() === 'true'}
                              inputAttributes={{ ref }}
                              label={t('OrderTypeDetails.ReleaseToShuttle.Label')}
                              onChange={(event) => onChange(event)}
                            />
                          )}
                        />
                        {errors.attributes &&
                          displayAttributeFieldValidationMessage(
                            errors,
                            getFormFieldIndexByKey(
                              getValues()['attributes'],
                              ORDER_TYPE_FIELD.RELEASE_TO_SHUTTLE
                            )
                          ) && (
                            <View direction="row" justify="space-between">
                              <FormControl.Error>
                                {displayAttributeFieldValidationMessage(
                                  errors,
                                  getFormFieldIndexByKey(
                                    getValues()['attributes'],
                                    ORDER_TYPE_FIELD.RELEASE_TO_SHUTTLE
                                  )
                                )}
                              </FormControl.Error>
                            </View>
                          )}
                      </FormControl>
                    </View.Item>
                  </View>
                  <View className={[styles['form__field-row']]} direction="row" gap={4}>
                    <View.Item>
                      <FormLabel text={t('OrderTypeDetails.MergeOptions.Label')} />
                    </View.Item>
                  </View>
                  <View direction="row" gap={4}>
                    <View.Item>
                      <FormControl
                        hasError={
                          !!(
                            errors.attributes &&
                            displayAttributeFieldValidationMessage(
                              errors,
                              getFormFieldIndexByKey(
                                getValues()['attributes'],
                                ORDER_TYPE_FIELD.ALLOW_MERGE
                              )
                            )
                          )
                        }
                      >
                        <Controller
                          control={control}
                          name={`attributes.${getFormFieldIndexByKey(
                            getValues()['attributes'],
                            ORDER_TYPE_FIELD.ALLOW_MERGE
                          )}.value`}
                          render={({ field: { onChange, value, ref } }) => (
                            <Checkbox
                              name="allowMerge"
                              value={value}
                              checked={value && value.toString() === 'true'}
                              inputAttributes={{ ref }}
                              label={t('OrderTypeDetails.AllowMerge.Label')}
                              onChange={(event) => onChange(event)}
                            />
                          )}
                        />
                        {errors.attributes &&
                          displayAttributeFieldValidationMessage(
                            errors,
                            getFormFieldIndexByKey(
                              getValues()['attributes'],
                              ORDER_TYPE_FIELD.ALLOW_MERGE
                            )
                          ) && (
                            <View direction="row" justify="space-between">
                              <FormControl.Error>
                                {displayAttributeFieldValidationMessage(
                                  errors,
                                  getFormFieldIndexByKey(
                                    getValues()['attributes'],
                                    ORDER_TYPE_FIELD.ALLOW_MERGE
                                  )
                                )}
                              </FormControl.Error>
                            </View>
                          )}
                      </FormControl>
                    </View.Item>
                  </View>
                </form>
              </View>
            </View>
          </View>
        </View>
      </>
    );
  }
};
