/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

export const RoleSchema = z.object({
  roleId: z.string(),
  locale: z.string().nullable().optional(),
  roleDesc: z.string().optional(),
});

export const DepartmentSchema = z.object({
  userDeptKey: z.string().optional(),
  deptId: z.string(),
  statusCd: z.string().optional(),
  primaryFl: z.boolean().optional(),
  locale: z.string().nullable().optional(),
  deptDesc: z.string().nullable().optional(),
});

export const ShiftSchema = z.object({
  shiftId: z.string(),
  shiftDesc: z.string().optional(),
  shiftIndex: z.number().optional(),
  locale: z.string().nullable().optional(),
  fromTime: z.string().optional(),
  toTime: z.string().optional(),
  dayOfWeek: z.string().optional(),
});

export const ShiftListSchema = z.array(ShiftSchema);

export const FacilitySchema = z.object({
  facilityUserKey: z.string().optional(),
  facilityId: z.string(),
  activeFromDate: z.string().optional().nullable(),
  activeToDate: z.string().optional().nullable(),
  wageCd: z.string().optional(),
  statusCd: z.string().optional(),
  primaryFl: z.boolean(),
  departments: z.array(DepartmentSchema).nullable().optional(),
  shifts: z.array(ShiftSchema).nullable().optional(),
  roles: z.array(RoleSchema).nullable().optional(),
});

export const UserSearchSchema = z.object({
  userKey: z.string(),
  userId: z.string(),
  employeeId: z.string().optional(),
  firstName: z.string().optional(),
  middleName: z.string().optional(),
  secondLastName: z.string().optional(),
  lastName: z.string().optional(),
  statusCd: z.string(),
  countryCd: z.string(),
  stateProvinceCd: z.string(),
  city: z.string(),
  addressLine1: z.string(),
  addressLine2: z.string(),
  postalCd: z.string(),
  hireDate: z.string(),
  jobCd: z.string(),
  jobTitle: z.string(),
  userPinCd: z.string().optional(),
  preferedLanguage: z.string(),
  multilingualFl: z.boolean().nullable(),
  temporaryFl: z.boolean().nullable(),
  clockedInFl: z.boolean().nullable(),
  facilities: z.array(FacilitySchema),
});

export const UserViewSchema = z.object({
  userKey: z.string(),
  userId: z.string(),
  employeeId: z.string(),
  firstName: z.string().min(1),
  middleName: z.string(),
  secondLastName: z.string(),
  lastName: z.string().min(1),
  statusCd: z.string().min(1),
  countryCd: z.string(),
  stateProvinceCd: z.string(),
  city: z.string(),
  addressLine1: z.string(),
  addressLine2: z.string(),
  postalCd: z.string(),
  hireDate: z.string(),
  jobCd: z.string(),
  jobTitle: z.string(),
  userPinCd: z.string().optional(),
  preferedLanguage: z.string().min(1),
  multilingualFl: z.boolean().nullable(),
  temporaryFl: z.boolean().nullable(),
  clockedInFl: z.boolean().nullable(),
  deptId: z.string().nullable(),
  shiftId: z.string().min(1),
  roleId: z.string(),
  wageCd: z.string().min(1),
  additionalFacilityId: z.string(),
  reportingToDeptId: z.string(),
  visitingStartDate: z.string(),
  visitingEndDate: z.string(),
});

export const UserRequestSchema = UserSearchSchema;
