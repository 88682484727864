/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';
import { t } from 'i18next';
import { MAX_LENGTH_FIELD, ORDER_TYPE_FIELD } from '@mdm/constants/constants.ts';
import { isAlphaNumeric } from '@shared/utils/validationUtils.ts';
import { AttributeSchema } from '@mdm/schemas/attributeSchema.ts';
// eslint-disable-next-line import/no-cycle
import {
  addCustomMaxLengthAttributeFieldValidation,
  addCustomNumericAttributeFieldValidation,
  addCustomRequiredAttributeFieldValidation,
} from '@mdm/utils/form/validationUtils.tsx';

export const OrderTypeListSchema = z.object({
  orderTypeCode: z.string(),
  description: z.string().nullable(),
  attributes: z.array(AttributeSchema),
});

export const defaultAttributeValues = [
  {
    name: ORDER_TYPE_FIELD.PRIORITY,
    value: '',
  },
  {
    name: ORDER_TYPE_FIELD.SHUTTLE_PRIORITY,
    value: '',
  },
  {
    name: ORDER_TYPE_FIELD.PALLET_THRESHOLD,
    value: '',
  },
  {
    name: ORDER_TYPE_FIELD.ALLOW_MERGE,
    value: 'false',
  },
  {
    name: ORDER_TYPE_FIELD.RELEASE_TO_LIGHT,
    value: 'false',
  },
  {
    name: ORDER_TYPE_FIELD.RELEASE_TO_SHUTTLE,
    value: 'false',
  },
];

export const OrderTypeViewSchema = z.object({
  orderTypeCode: z
    .string()
    .default('')
    .superRefine((val, ctx) => {
      if (val == '') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('Form.RequiredField'),
        });
      }

      if (val.length > MAX_LENGTH_FIELD.ORDER_TYPE_FIELD) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          maximum: MAX_LENGTH_FIELD.ORDER_TYPE_FIELD,
          type: 'string',
          inclusive: true,
          message: t('Form.MaxCharacterAmount', { count: MAX_LENGTH_FIELD.ORDER_TYPE_FIELD }),
        });
      }

      if (!isAlphaNumeric(val)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('Form.AlphaNumericError'),
        });
      }
    }),
  description: z
    .string()
    .nullable()
    .default('')
    .superRefine((val, ctx) => {
      if (val == '') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('Form.RequiredField'),
        });
      }

      if (val && val.length > MAX_LENGTH_FIELD.ORDER_TYPE_DESCRIPTION) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          maximum: MAX_LENGTH_FIELD.ORDER_TYPE_DESCRIPTION,
          type: 'string',
          inclusive: true,
          message: t('Form.MaxCharacterAmount', { count: MAX_LENGTH_FIELD.ORDER_TYPE_DESCRIPTION }),
        });
      }
    }),
  attributes: z
    .array(AttributeSchema)
    .superRefine((data, ctx) => {
      const requiredFields = [ORDER_TYPE_FIELD.PRIORITY];
      addCustomRequiredAttributeFieldValidation(data, requiredFields, ctx);

      const numericFields = [
        {
          key: ORDER_TYPE_FIELD.PRIORITY,
        },
        {
          key: ORDER_TYPE_FIELD.SHUTTLE_PRIORITY,
        },
        {
          key: ORDER_TYPE_FIELD.PALLET_THRESHOLD,
        },
      ];

      addCustomNumericAttributeFieldValidation(data, numericFields, ctx);

      const maxLengthFields = [
        {
          name: ORDER_TYPE_FIELD.PRIORITY,
          maxLength: MAX_LENGTH_FIELD.ORDER_TYPE_PRIORITY,
        },
        {
          name: ORDER_TYPE_FIELD.PALLET_THRESHOLD,
          maxLength: MAX_LENGTH_FIELD.ORDER_TYPE_PALLET_THRESHOLD,
        },
        {
          name: ORDER_TYPE_FIELD.SHUTTLE_PRIORITY,
          maxLength: MAX_LENGTH_FIELD.ORDER_TYPE_SHUTTLE_PRIORITY,
        },
      ];
      addCustomMaxLengthAttributeFieldValidation(data, maxLengthFields, ctx);
    })
    .default(defaultAttributeValues),
});
