/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

import { MAX_TEXTAREA_LENGTH } from '@shared/constants/constants';
import { isAlphaNumeric } from '@shared/utils/validationUtils';

import { Lengths } from '@inbound/constants/constants';

import { TrailerInboundTaskSchema } from '@inbound/schemas/inboundTaskSchema';
import { InboundUserSchema } from '@inbound/schemas/userSchema';

import { isNumeric } from '@inbound/utils/utils';

/* Trailer arrival update schema */
export const TrailerArrivalUpdateReceivedOrderLineSchema = z.object({
  receiveOrderLineKey: z.string().optional().nullable(),
  receivedByUserId: z.string().optional().nullable(),
  receivedTs: z.string().optional().nullable(),
  partNbrId: z.string().optional().nullable(),
  expiryDate: z.string().optional().nullable(),
  receivedQty: z.number().optional().nullable(),
  tourLbl: z.string().optional().nullable(),
  tourLblStartTs: z.string().optional().nullable(),
  tourLblEndTs: z.string().optional().nullable(),
});

export const TrailerArrivalUpdateInboundOrderLineLocationSchema = z.object({
  inboundOrderLineLocationKey: z.string().optional().nullable(),
  layoutDistinctName: z.string().optional().nullable(),
  layoutName: z.string().optional().nullable(),
  layoutAttribNameType: z.string().optional().nullable(),
  parentLayoutDistinctName: z.string().optional().nullable(),
  receivedQtyAdj: z.number().optional().nullable(),
  adjReasonCd: z.string().optional().nullable(),
  receivedOrderLines: z.array(TrailerArrivalUpdateReceivedOrderLineSchema).optional(),
});

export const TrailerArrivalUpdateInboundOrderLineSchema = z.object({
  inboundOrderLineKey: z.string().optional().nullable(),
  productId: z.string().optional().nullable(),
  statusCd: z.string().optional().nullable(),
  inboundOrderLineLocations: z.array(TrailerArrivalUpdateInboundOrderLineLocationSchema).optional(),
});

export const TrailerArrivalUpdateInboundOrderSchema = z.object({
  domainTypeCd: z.string().optional().nullable(),
  orderTypeCd: z.string().optional().nullable(),
  statusCd: z.string().optional().nullable(),
  subStatusCd: z.string().optional().nullable(),
  statusRevertReason: z.string().optional().nullable(),
  prevStatusCd: z.string().optional().nullable(),
  priority: z.number().optional().nullable(),
  orderLocationTypeCd: z.string().optional().nullable(),
  orderLocationId: z
    .string()
    .optional()
    .nullable()
    .refine((value) => isAlphaNumeric(value || '') || value === ''),
  shipToOrgId: z.string().optional().nullable(),
  shipToFacilityId: z.string().optional().nullable(),
  finalizedByUserName: z.string().optional().nullable(),
  tasks: z.array(TrailerInboundTaskSchema).optional().nullable(),
  inboundOrderLines: z.array(TrailerArrivalUpdateInboundOrderLineSchema).optional().nullable(),
  users: z.array(InboundUserSchema).optional().nullable(),
});

export const TrailerArrivalUpdateTrailerOrderSchema = z.object({
  trailerOrderKey: z.string().optional().nullable(),
  statusCd: z.string().optional().nullable(),
  commodityTypeCd: z.string().min(1),
  comment: z.string().optional().nullable(),
  inboundOrder: TrailerArrivalUpdateInboundOrderSchema.optional(),
});

export const TrailerArrivalUpdateSchema = z.object({
  trailerArrivalKey: z.string().optional().nullable(),
  trailerArrivalTs: z.string().optional(),
  trailerCarrierName: z.string().max(Lengths.MAX_CARRIER_NAME),
  trailerNbr: z.string().max(Lengths.MAX_TRAILER_NUMBER),
  trailerLocationTypeCd: z.string(),
  trailerLocationId: z.string().max(Lengths.MAX_LOCATION),
  contactName: z.string().optional().default('').nullable(),
  contactPhone: z.string().optional().default('').nullable(),
  contactEmail: z.string().optional().default('').nullable(),
  trailerOrders: TrailerArrivalUpdateTrailerOrderSchema,
  priority: z.boolean().optional(),
});

export const TrailerArrivalUpdateRequestSchema = z.object({
  trailerArrivalTs: z.string().optional(),
  trailerCarrierName: z.string().optional(),
  trailerNbr: z.string().optional(),
  trailerLocationTypeCd: z.string(),
  trailerLocationId: z.string(),
  contactName: z.string().optional().default('').nullable(),
  contactPhone: z.string().optional().default('').nullable(),
  contactEmail: z.string().optional().default('').nullable(),
  trailerOrders: z.array(TrailerArrivalUpdateTrailerOrderSchema),
});

export const TrailerArrivalTrailerOrderSchema = z.object({
  sourceOrderNbr: z
    .string()
    .min(Lengths.SOURCE_ORDER_NUMBER)
    .max(Lengths.SOURCE_ORDER_NUMBER)
    .refine((sourceOrderNbr) => isNumeric(sourceOrderNbr)),
  commodityTypeCd: z.string().min(1),
  inboundOrder: z.object({
    priority: z.number().optional(),
    orderLocationTypeCd: z.string().optional().nullable(),
    orderLocationId: z
      .string()
      .max(Lengths.RECEIVING_DOOR)
      .optional()
      .nullable()
      .refine((value) => isAlphaNumeric(value || '') || value === ''),
  }),
  comment: z.string().max(MAX_TEXTAREA_LENGTH).optional(),
});

export const TrailerArrivalSchema = z.object({
  trailerArrivalTs: z.string().optional(),
  trailerCarrierName: z.string().max(Lengths.MAX_CARRIER_NAME).optional(),
  trailerNbr: z.string().max(Lengths.MAX_TRAILER_NUMBER).optional(),
  trailerLocationTypeCd: z.string(),
  trailerLocationId: z.string().max(Lengths.MAX_LOCATION),
  contactName: z.string().optional().default('').nullable(),
  contactPhone: z.string().optional().default('').nullable(),
  contactEmail: z.string().optional().default('').nullable(),
  trailerOrders: TrailerArrivalTrailerOrderSchema,
  priority: z.boolean().optional(),
});

export const TrailerArrivalRequestSchema = z.object({
  trailerArrivalTs: z.string().optional(),
  trailerCarrierName: z.string().optional(),
  trailerNbr: z.string().optional(),
  trailerLocationTypeCd: z.string(),
  trailerLocationId: z.string(),
  contactName: z.string().optional().nullable(),
  contactPhone: z.string().optional().nullable(),
  contactEmail: z.string().optional().nullable(),
  trailerOrders: z.array(TrailerArrivalTrailerOrderSchema),
});
