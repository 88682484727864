/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  Accordion,
  Button,
  Icon,
  Link,
  SearchBar,
  Text,
  View,
  Notification,
  InfoCircle,
  Divider,
} from '@az/starc-ui';
import {
  ActionDelete,
  Add,
  ArrowRight,
  CheckmarkCircle,
  IconError,
  Warning,
} from '@az/starc-ui-icons';

import { Ellipses } from '@shared/assets/icons';
import { Avatar } from '@shared/components/Avatar/Avatar';
import { CommentCardProps } from '@shared/components/CommentCard/CommentCard';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle';
import { WMSInlineNotification } from '@shared/components/Notification/Notification';
import {
  ACTIONS,
  DEPARTMENTS,
  EMPTY_VALUE,
  ENTITY_ASSOCIATIONS,
  FIRST_NAME,
  USER_STATUS_CD,
} from '@shared/constants/constants';

import { ActivityLog } from '@shared/components/ActivityLog/ActivityLog';
import { PAGE_URLS, ROUTES } from '@shared/constants/routes';
import { useGetTaskSearchData } from '@shared/services/hooks/useGetTaskSearchData';
import { useUsersSearch } from '@shared/services/hooks/useUsersSearch';
import { CommentSchemaType, UserSearchSchemaType } from '@shared/types/schema.type';
import { EmptyPage } from '@shared/components/EmptyPage/EmptyPage';

import { EditPOModal } from '@inbound/components/EditPOModal/EditPOModal';
import { POStatusModal } from '@inbound/components/POStatusModal/POStatusModal';
import { RemovePOModal } from '@inbound/components/RemovePOModal/RemovePOModal';
import { RecommendedAssignees } from '@inbound/components/RecommendedAssignees/RecommendedAssignees';
import { AddEditAssigneesModal } from '@inbound/components/AddEditAssigneesModal/AddEditAssigneesModal';
import { FinalizePOModal } from '@inbound/components/FinalizePOModal/FinalizePOModal';
import {
  ASC,
  DEFAULT_PAGE,
  DOMAIN_TYPE_CD,
  INBOUND_ORDER_LINE_STATUS,
  INBOUND_ORDER_STATUS,
  NOT_STARTED,
  PAGE_SIZE,
  QUANTITY_FINALIZED,
  QUANTITY_MATCHED,
  QUANTITY_UNMATCHED,
  SORT_BY_PRIORITY,
  SOURCE_ORDER_NUMBER,
  TRAILER_ORDER_STATUS,
} from '@inbound/constants/constants';
import { useTrailerArrivalUpdater } from '@inbound/hooks/useTrailerArrivalUpdater';
import { useGetInboundOrderBySourceAndReceiptId } from '@inbound/services/hooks/useGetInboundOrderBySourceOrderAndReceiptId';
import { useGetTrailerOrders } from '@inbound/services/hooks/useGetTrailerOrders';
import {
  InboundTrailerLineType,
  TrailerArrivalUpdateType,
  TrailerOrderType,
} from '@inbound/types/types';
import { replaceAll, statusToBadgeVariant } from '@inbound/utils/utils';

import { PODetailSkeleton } from './PODetailSkeleton';
import { ProductTabs } from './ProductTabs/ProductTabs';

import { PODetailsSection } from './PODetailsSection/PODetailsSection';
import { ProductTableSection } from './ProductTableSection/ProductTableSection';

import { RenderMaterTitleSubTitle } from './PODetailsSection/PODetailMasterSubTitle';
import { PODetailStatatics } from './PODetailsSection/PODetailStatatics';

import styles from './PODetail.module.scss';

export const PODetail = () => {
  /* State variables */
  const [isHighPriority, setIsHighPriority] = useState<boolean | undefined>(false);
  const [trailerOrderData, setTrailerOrderData] = useState<TrailerOrderType>();
  const [orderStatus, setOrderStatus] = useState<string>();
  const [searchValue, setSearchValue] = useState<string>();
  const [quantityMatchedData, setQuantityMatchedData] = useState<InboundTrailerLineType[]>([]);
  const [quantityUnmatchedData, setQuantityUnmatchedData] = useState<InboundTrailerLineType[]>([]);
  const [quantityFinalizedData, setQuantityFinalizedData] = useState<InboundTrailerLineType[]>([]);

  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [trailerArrivalEditData, setTrailerArrivalEditData] = useState<TrailerArrivalUpdateType>();
  const [showPOEditModal, setShowPOEditModal] = useState<boolean>(false);
  const [isShowOnHold, setIsShowOnHold] = useState<boolean>(false);
  const [showPOHoldModal, setShowPOHoldModal] = useState<boolean>(false);
  const [showPORemoveModal, setShowPORemoveModal] = useState<boolean>(false);
  const [showAddEditAssigneesModal, setShowAddEditAssigneesModal] = useState<boolean>(false);
  const [showFinalizePOModal, setShowFinalizePOModal] = useState<boolean>(false);
  const [comments, setComments] = useState<CommentCardProps[]>([]);
  const [previousStatus, setPreviousStatus] = useState<string>();
  const [isPOICReviewCompleted, setIsPOICReviewCompleted] = useState(false);
  const [isRemovingAssignee, setIsRemovingAssignee] = useState(false);
  const [showRevertReceivingInProgressBanner, setShowRevertReceivingInProgressBanner] =
    useState(true);
  /* Constants */
  const { t } = useTranslation();
  const { sourceOrderId: sourceOrderId, receiptId: receiptId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const maxRecommendedAssignee = 5;

  const { updateTrailerArrival, isLoadingUpdate } = useTrailerArrivalUpdater();

  const tabTitles = {
    QUANTITY_UNMATCHED: t('CombinedTabs.PODetails.QuantityUnmatched'),
    QUANTITY_MATCHED: t('CombinedTabs.PODetails.QuantityMatched'),
  };

  const breadcrumbs = {
    data: [
      {
        label: t('PODashboard.Title'),
        onClick: () => navigate(PAGE_URLS.PO_DASHBOARD),
      },
      {
        label: t('PODashboard.PoWithNumber', {
          poNumber: sourceOrderId,
        }),
        onClick: () => navigate(''),
      },
    ],
  };

  const tabs = [
    {
      name: tabTitles.QUANTITY_UNMATCHED,
      numberOfItems: quantityUnmatchedData.length,
      value: QUANTITY_UNMATCHED,
      content: (
        <ProductTableSection
          data={quantityUnmatchedData}
          isLoading={isLoadingData}
          type={QUANTITY_UNMATCHED}
          searchValue={searchValue}
        />
      ),
    },
    {
      name: tabTitles.QUANTITY_MATCHED,
      numberOfItems: quantityMatchedData.length,
      value: QUANTITY_MATCHED,
      content: (
        <ProductTableSection
          data={quantityMatchedData}
          isLoading={isLoadingData}
          type={QUANTITY_MATCHED}
          searchValue={searchValue}
        />
      ),
    },
  ];

  /* Queries */
  const {
    inboundOrderData,
    isLoading: isLoadingInboundOrder,
    isError: isErrorInboundOrder,
  } = useGetInboundOrderBySourceAndReceiptId(
    {
      sourceOrderNumber: sourceOrderId || '',
      receiptId: receiptId || '',
    },
    true
  );

  const {
    trailerOrdersData,
    isLoading: isLoadingTrailerOrders,
    isError: isErrorTrailerOrders,
  } = useGetTrailerOrders(
    {
      searchPage: {
        currentPage: DEFAULT_PAGE,
        pageSize: PAGE_SIZE * 20,
        sortBy: SOURCE_ORDER_NUMBER,
        direction: ASC,
      },
      searchCriteria: {
        inboundOrder: {
          domainTypeCd: DOMAIN_TYPE_CD,
          sourceOrderNbr: sourceOrderId,
          statusCds: [orderStatus ? orderStatus : NOT_STARTED],
        },
      },
    },
    !!orderStatus
  );

  const { usersSearchData: recommendedAssigneeData } = useUsersSearch({
    searchPage: {
      page: DEFAULT_PAGE,
      size: PAGE_SIZE,
      sortBy: FIRST_NAME,
      direction: ASC,
    },
    searchCriteria: {
      deptId: DEPARTMENTS.INBOUND,
    },
  });

  const { taskSearchData, refetch } = useGetTaskSearchData(
    {
      searchPage: {
        page: DEFAULT_PAGE,
        size: PAGE_SIZE,
        sortBy: SORT_BY_PRIORITY,
        direction: ASC,
      },
      searchCriteria: {
        taskNo: inboundOrderData?.trailerOrder.inboundOrder.tasks?.taskNbr,
        entityAssociations: [ENTITY_ASSOCIATIONS.COMMENTS],
      },
    },
    false
  );

  /* Functions */
  const isExistingAssignees = () =>
    inboundOrderData?.trailerOrder?.inboundOrder.users.filter(
      (item) => item.userStatusCd !== USER_STATUS_CD.CANCEL
    ).length;

  const isSuggestedAssignees = () =>
    recommendedAssigneeData?.content
      .filter(
        (user) =>
          !inboundOrderData?.trailerOrder?.inboundOrder.users
            .filter((item) => item.userStatusCd !== USER_STATUS_CD.CANCEL)
            .map((user) => user.assignedUserId)
            .includes(user.userId)
      )
      .slice(0, maxRecommendedAssignee).length;

  const onCloseEdit = () => {
    setShowPOEditModal(false);

    if (location.pathname.indexOf(ROUTES.EDIT) !== -1) {
      navigate(PAGE_URLS.PO_DASHBOARD);
    }
  };

  const isPOStatus = useCallback(
    (status: string) => {
      if (inboundOrderData && inboundOrderData.trailerOrder.inboundOrder.statusCd === status) {
        return true;
      } else {
        return false;
      }
    },
    [inboundOrderData]
  );

  const onClosePutPOHold = (status: string | null) => {
    setShowPOHoldModal(false);

    if (status === ACTIONS.CLOSE && !isPOStatus(TRAILER_ORDER_STATUS.HOLD)) {
      navigate(PAGE_URLS.PO_DASHBOARD);
    }
  };

  const onCloseRemove = () => {
    setShowPORemoveModal(false);
    navigate(PAGE_URLS.PO_DASHBOARD);
  };

  const isPORevertReceivingInProgress = () => {
    if (
      inboundOrderData &&
      inboundOrderData.trailerOrder.inboundOrder.statusRevertReason &&
      inboundOrderData.trailerOrder.inboundOrder.prevStatusCd ===
        TRAILER_ORDER_STATUS.IN_IC_REVIEW &&
      inboundOrderData.trailerOrder.inboundOrder.statusCd ===
        TRAILER_ORDER_STATUS.RECEIVING_IN_PROGRESS
    ) {
      return true;
    } else {
      return false;
    }
  };

  const showPOAction = useCallback(() => {
    if (
      isPOStatus(TRAILER_ORDER_STATUS.READY_FOR_SIGNATURE) ||
      isPOStatus(TRAILER_ORDER_STATUS.FINALIZED)
    ) {
      return false;
    } else {
      return true;
    }
  }, [isPOStatus]);

  const onCloseFinalize = () => {
    setShowFinalizePOModal(false);
  };

  const onPORevertReceivingInProgressBannerClose = () => {
    setShowRevertReceivingInProgressBanner(false);
    setShowAddEditAssigneesModal(true);
  };

  const onOpenAddEditAssignees = (
    e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();

    setShowAddEditAssigneesModal(true);
  };

  const onAssigneeSelect = (user: UserSearchSchemaType) => {
    if (trailerArrivalEditData && receiptId && user) {
      const payload = {
        ...trailerArrivalEditData,
        trailerOrders: [
          {
            trailerOrderKey: trailerArrivalEditData.trailerOrders?.trailerOrderKey,
            commodityTypeCd: trailerArrivalEditData.trailerOrders?.commodityTypeCd,
            inboundOrder: {
              users: [
                {
                  assignedUserId: user.userId,
                  assignedUserName: `${user.firstName.trim()} ${user.lastName.trim()}`,
                  userStatusCd: USER_STATUS_CD.ASSIGNED,
                },
              ],
            },
          },
        ],
      };

      updateTrailerArrival({
        payload,
        receiptId,
        sourceOrderId,
      });
    }
  };

  const onCloseAddEditAssignees = () => {
    setShowAddEditAssigneesModal(false);
  };

  const onSubmitAssignees = () => {
    onCloseAddEditAssignees();
  };

  const onRemoveAssignee = (userId: string) => {
    if (trailerArrivalEditData && userId) {
      const assignedUsersData = trailerArrivalEditData.trailerOrders.inboundOrder?.users;
      setIsRemovingAssignee(true);

      if (assignedUsersData) {
        const i = assignedUsersData.findIndex((assignee) => assignee.assignedUserId === userId);

        if (i > -1) {
          assignedUsersData[i].userStatusCd = USER_STATUS_CD.CANCEL;
        }
      }

      const payload = {
        ...trailerArrivalEditData,
        trailerOrders: [
          {
            trailerOrderKey: trailerArrivalEditData.trailerOrders?.trailerOrderKey,
            commodityTypeCd: trailerArrivalEditData.trailerOrders?.commodityTypeCd,
            inboundOrder: {
              users: assignedUsersData,
            },
          },
        ],
      };

      updateTrailerArrival({
        payload,
        receiptId,
        sourceOrderId,
        successCallBack: () => {
          setIsRemovingAssignee(false);
        },
      });
    }
  };

  const onChangePriority = (priority: boolean) => {
    if (trailerArrivalEditData && receiptId) {
      const payload = {
        ...trailerArrivalEditData,
        trailerOrders: [
          {
            trailerOrderKey: trailerArrivalEditData.trailerOrders?.trailerOrderKey,
            commodityTypeCd: trailerArrivalEditData.trailerOrders?.commodityTypeCd,
            inboundOrder: {
              priority: priority ? 1 : 0,
            },
          },
        ],
      };

      updateTrailerArrival({
        payload,
        receiptId,
        sourceOrderId,
      });
    }
  };

  const addActivityComment = (newComment: CommentCardProps) => {
    if (trailerArrivalEditData && receiptId) {
      const payload = {
        ...trailerArrivalEditData,
        trailerOrders: [
          {
            trailerOrderKey: trailerArrivalEditData.trailerOrders?.trailerOrderKey,
            comment: newComment.comment,
            commodityTypeCd: trailerArrivalEditData.trailerOrders?.commodityTypeCd,
          },
        ],
      };

      updateTrailerArrival({
        payload,
        receiptId,
        sourceOrderId,
        errorCallBack: () => {
          if (comments !== undefined) {
            const updatedComments = [...comments];
            updatedComments.pop();
            setComments(updatedComments);
          } else {
            setComments([]);
          }
        },
      });

      setComments([newComment, ...comments]);
    }
  };

  const onFinalize = () => {
    if (trailerArrivalEditData && receiptId) {
      const payload = {
        ...trailerArrivalEditData,
        trailerOrders: [
          {
            trailerOrderKey: trailerArrivalEditData.trailerOrders?.trailerOrderKey,
            commodityTypeCd: trailerArrivalEditData.trailerOrders?.commodityTypeCd,
            inboundOrder: {
              statusCd: TRAILER_ORDER_STATUS.READY_FOR_FINALIZATION,
            },
          },
        ],
      };
      if (!isPOStatus(TRAILER_ORDER_STATUS.IN_IC_REVIEW)) {
        updateTrailerArrival({
          payload,
          receiptId,
          sourceOrderId,
        });
      }
      navigate(PAGE_URLS.PO_FINALIZATION(sourceOrderId ?? '', receiptId));
    }
  };

  /* Hooks */
  useEffect(() => {
    if (inboundOrderData) {
      if (showPOAction()) {
        if (location.pathname.indexOf(ROUTES.EDIT) !== -1) {
          setShowPOEditModal(true);
        } else if (location.pathname.indexOf(ROUTES.PUT_ON_HOLD) !== -1) {
          setIsShowOnHold(true);
        } else if (location.pathname.indexOf(ROUTES.REMOVE) !== -1) {
          setShowPORemoveModal(true);
        }
      }
    }
  }, [location, inboundOrderData, showPOAction]);

  useEffect(() => {
    if (inboundOrderData) {
      setOrderStatus(inboundOrderData.trailerOrder.inboundOrder.statusCd);

      if (inboundOrderData.trailerOrder.inboundOrder.inboundOrderLines) {
        setQuantityFinalizedData(inboundOrderData.trailerOrder.inboundOrder.inboundOrderLines);

        setQuantityMatchedData(
          inboundOrderData.trailerOrder.inboundOrder.inboundOrderLines.filter(
            (inboundOrderLine) =>
              inboundOrderLine.totalOrderedQty === inboundOrderLine.totalReceivedQty ||
              (inboundOrderLine.totalReceivedQtyAdj &&
                inboundOrderLine.totalReceivedQtyAdj === inboundOrderLine.totalOrderedQty) ||
              inboundOrderLine.statusCd === INBOUND_ORDER_LINE_STATUS.ACCEPTED
          )
        );

        setQuantityUnmatchedData(
          inboundOrderData.trailerOrder.inboundOrder.inboundOrderLines.filter(
            (inboundOrderLine) =>
              inboundOrderLine.totalOrderedQty !== inboundOrderLine.totalReceivedQty &&
              inboundOrderLine.totalReceivedQtyAdj !== inboundOrderLine.totalOrderedQty &&
              inboundOrderLine.statusCd !== INBOUND_ORDER_LINE_STATUS.ACCEPTED
          )
        );
      }

      setIsHighPriority(inboundOrderData.trailerOrder.inboundOrder.priority ? true : false);

      setIsLoadingData(false);
    }
  }, [inboundOrderData]);

  useEffect(() => {
    if (trailerOrdersData) {
      const trailerOrder = trailerOrdersData.content.filter(
        (orderItem) => orderItem.trailerOrder.receiptId === receiptId
      );

      if (trailerOrder.length > 0) {
        setTrailerOrderData(trailerOrder[0].trailerOrder);
      }
    }
  }, [receiptId, trailerOrdersData]);

  useEffect(() => {
    if (inboundOrderData && trailerOrderData) {
      setTrailerArrivalEditData({
        trailerArrivalTs: trailerOrderData.trailerArrival.trailerArrivalTs,
        trailerCarrierName: trailerOrderData.trailerArrival.trailerCarrierName || '',
        trailerNbr: trailerOrderData.trailerArrival.trailerNbr || '',
        trailerLocationTypeCd: trailerOrderData.trailerArrival.trailerLocationTypeCd || '',
        trailerLocationId: trailerOrderData.trailerArrival.trailerLocationId || '',
        trailerOrders: {
          trailerOrderKey: inboundOrderData.trailerOrder.trailerOrderKey,
          statusCd: inboundOrderData.trailerOrder.statusCd,
          commodityTypeCd: inboundOrderData.trailerOrder.commodityTypeCd,
          inboundOrder: {
            priority: inboundOrderData.trailerOrder.inboundOrder.priority,
            orderLocationTypeCd: inboundOrderData.trailerOrder.inboundOrder.orderLocationTypeCd,
            orderLocationId: inboundOrderData.trailerOrder.inboundOrder.orderLocationId,
            users: inboundOrderData.trailerOrder.inboundOrder.users,
            statusCd: inboundOrderData.trailerOrder.inboundOrder.statusCd,
          },
        },
        priority: inboundOrderData.trailerOrder.inboundOrder.priority ? true : false,
        contactName: trailerOrderData.trailerArrival.contactName ?? null,
        contactPhone: trailerOrderData.trailerArrival.contactPhone ?? null,
        contactEmail: trailerOrderData.trailerArrival.contactEmail ?? null,
      });
    }
  }, [inboundOrderData, trailerOrderData]);

  useEffect(() => {
    if (taskSearchData && taskSearchData.content.length > 0) {
      const userComments = taskSearchData.content[0].comments;
      const taskComments =
        (userComments &&
          userComments
            .filter(
              (comment: CommentSchemaType) => comment.comment !== null && comment.userId !== null
            )
            .map((comment: CommentSchemaType) => ({
              comment: comment.comment || '',
              username: comment.userId || '',
              timestamp: new Date(comment?.createTs || ''),
            }))) ||
        [];
      setComments(taskComments);
    }
  }, [taskSearchData]);

  useEffect(() => {
    if (inboundOrderData?.trailerOrder.inboundOrder.tasks?.taskNbr) {
      refetch();
    }
  }, [inboundOrderData, refetch]);

  //checking for IC Review Completed
  useEffect(() => {
    if (inboundOrderData) {
      const currentStatus = inboundOrderData.trailerOrder.inboundOrder.statusCd;

      if (
        previousStatus === TRAILER_ORDER_STATUS.IN_IC_REVIEW &&
        currentStatus === TRAILER_ORDER_STATUS.READY_FOR_FINALIZATION
      ) {
        setIsPOICReviewCompleted(true);
      } else {
        setIsPOICReviewCompleted(false);
      }

      setPreviousStatus(currentStatus); // Update the previous status
    }
  }, [inboundOrderData, previousStatus]);

  if (isLoadingInboundOrder || isLoadingTrailerOrders) {
    return <PODetailSkeleton />;
  } else if (isErrorTrailerOrders || isErrorInboundOrder) {
    return (
      <EmptyPage
        title={t('Errors.Page.Title')}
        description={t('Errors.Page.Description', { service: t('Services.Order') })}
        buttonText={t('404.ButtonText')}
        onClick={() => navigate(PAGE_URLS.PO_DASHBOARD)}
        icon={IconError}
      />
    );
  } else {
    return (
      <View direction="column" height="100%" className={styles['po-detail']}>
        {inboundOrderData && trailerOrderData && (
          <>
            <MasterTitle
              title={
                inboundOrderData?.trailerOrder.inboundOrder.vendorLocale?.vendorName || EMPTY_VALUE
              }
              breadcrumbProps={breadcrumbs}
              statusBadgeProps={{
                variant: statusToBadgeVariant(inboundOrderData.trailerOrder.inboundOrder.statusCd),
                text: replaceAll(inboundOrderData.trailerOrder.inboundOrder.statusCd, '_', ' '),
              }}
              subtitle={
                <RenderMaterTitleSubTitle
                  inboundOrderData={inboundOrderData}
                  isHighPriority={isHighPriority}
                  sourceOrderId={sourceOrderId ?? ''}
                />
              }
            >
              <View direction="row" justify="end" align="center" gap={4}>
                <View.Item>
                  <ActivityLog
                    comments={comments}
                    handleCommentSubmit={addActivityComment}
                    isLoading={false}
                  />
                </View.Item>

                {isShowOnHold && !isPOStatus(TRAILER_ORDER_STATUS.HOLD) && showPOAction() && (
                  <View.Item>
                    <Button
                      size="large"
                      onClick={() => setShowPOHoldModal(true)}
                      variant="secondary"
                    >
                      <View direction="row">
                        <Text>{t('PODashboard.Actions.PutPOOnHold')}</Text>
                      </View>
                    </Button>
                  </View.Item>
                )}

                <View.Item>
                  <View direction="row" gap={4}>
                    {isPOStatus(TRAILER_ORDER_STATUS.HOLD) && showPOAction() && (
                      <Button size="large" onClick={() => setShowPOHoldModal(true)}>
                        <View direction="row">
                          <Text>{t('PODashboard.Actions.RemoveHold')}</Text>
                        </View>
                      </Button>
                    )}

                    {isPOStatus(TRAILER_ORDER_STATUS.READY_FOR_SIGNATURE) && (
                      <Button
                        size="large"
                        onClick={() => {
                          setShowFinalizePOModal(true);
                        }}
                      >
                        <View direction="row">
                          <Text>{t('PODashboard.Finalize')}</Text>
                        </View>
                      </Button>
                    )}

                    {isPOStatus(TRAILER_ORDER_STATUS.READY_FOR_SIGNATURE) && (
                      <Button
                        size="large"
                        onClick={() => {
                          onFinalize();
                        }}
                        disabled={
                          !(
                            isPOStatus(TRAILER_ORDER_STATUS.RECEIVING_IN_PROGRESS) ||
                            isPOStatus(TRAILER_ORDER_STATUS.READY_FOR_FINALIZATION) ||
                            isPOStatus(TRAILER_ORDER_STATUS.IN_IC_REVIEW)
                          )
                        }
                      >
                        <View direction="row">
                          <Text>{t('PODashboard.FinalizeBtn')}</Text>
                        </View>
                      </Button>
                    )}
                  </View>
                </View.Item>

                {showPOAction() && (
                  <View.Item>
                    <View direction="row">
                      <Link
                        onClick={() => {
                          return;
                        }}
                      >
                        <Icon svg={Ellipses} />
                      </Link>
                    </View>
                  </View.Item>
                )}
              </View>
            </MasterTitle>
          </>
        )}

        {inboundOrderData && trailerOrderData && (
          <>
            <View direction="row" width="100%" height="100%" wrap={false}>
              <View.Item
                columns={{ s: 4, m: 4, l: 4, xl: 3 }}
                className={styles['po-detail__left-column']}
                attributes={{
                  'data-testid': 'po-details-left-column',
                }}
              >
                <View>
                  <Accordion
                    className={styles['po-detail__left-column__accordion']}
                    headerOptions={{
                      triggerInnerClassName:
                        styles['po-detail__left-column__accordion__trigger-inner'],
                      headerElement: (
                        <View direction="row">
                          <View.Item grow>
                            <Text
                              color="primary"
                              size="100"
                              weight="bold"
                              className={styles['po-detail__left-column__accordion__header']}
                            >
                              {t('PODashboard.Assignees.Title')}
                            </Text>
                          </View.Item>
                          {showPOAction() && (
                            <View.Item>
                              <Link
                                className={
                                  styles['po-detail__left-column__accordion__auxiliary-label__link']
                                }
                                onClick={(e) => onOpenAddEditAssignees(e)}
                                startIcon={Add}
                              >
                                {inboundOrderData.trailerOrder?.inboundOrder.users.filter(
                                  (item) => item.userStatusCd !== USER_STATUS_CD.CANCEL
                                ).length === 0
                                  ? t('PODashboard.Assignees.AddAssignee')
                                  : t('PODashboard.Assignees.EditAssignee')}
                              </Link>
                            </View.Item>
                          )}
                        </View>
                      ),
                    }}
                  >
                    {!isExistingAssignees() && (
                      <View
                        padding={[2, 0, 0, 0]}
                        className={styles['recommended-assignees__text']}
                      >
                        <Text color="600" size="100">
                          {t('PODashboard.Assignees.NoAssignee')}
                        </Text>
                      </View>
                    )}
                    <View
                      direction="column"
                      padding={[4, 2, 0, 0]}
                      className={
                        styles['po-detail__left-column__accordion__assignees_items_wrapper']
                      }
                    >
                      {inboundOrderData.trailerOrder?.inboundOrder.users
                        .filter((item) => item.userStatusCd !== USER_STATUS_CD.CANCEL)
                        .map((user, index) => (
                          <View.Item
                            key={t('PODashboard.ItemKey', { item: 'assignees', key: index })}
                          >
                            <View direction="row" align="center">
                              <View.Item grow>
                                <Avatar
                                  name={user.assignedUserName}
                                  size="large"
                                  variant="assignee"
                                  showText={true}
                                  className={styles['assignees__avatar__container']}
                                  textClassName={styles['assignees__avatar__text']}
                                />
                              </View.Item>

                              {showPOAction() && (
                                <View.Item>
                                  <Button
                                    variant="pill"
                                    className={
                                      styles[
                                        'po-detail__left-column__accordion__assignees_items_wrapper__item__button'
                                      ]
                                    }
                                    onClick={() => onRemoveAssignee(user.assignedUserId)}
                                  >
                                    {t('PODashboard.Actions.Remove')}
                                  </Button>
                                </View.Item>
                              )}
                            </View>
                          </View.Item>
                        ))}
                      {isExistingAssignees() && isSuggestedAssignees() && (
                        <View
                          padding={[0, 0, 1, 0]}
                          direction="row"
                          justify="center"
                          align="center"
                        >
                          <View.Item>
                            <View padding={[0, 2, 0, 0]}>
                              <Text>{t('PODashboard.Assignees.Suggested')}</Text>
                            </View>
                          </View.Item>
                          <View.Item grow>
                            <Divider color="300" />
                          </View.Item>
                        </View>
                      )}

                      <RecommendedAssignees
                        assignees={
                          recommendedAssigneeData?.content
                            .filter(
                              (user) =>
                                !inboundOrderData.trailerOrder?.inboundOrder.users
                                  .filter((item) => item.userStatusCd !== USER_STATUS_CD.CANCEL)
                                  .map((user) => user.assignedUserId)
                                  .includes(user.userId)
                            )
                            .slice(0, maxRecommendedAssignee) ?? []
                        }
                        isLoading={isLoadingUpdate}
                        isRemoving={isRemovingAssignee}
                        onButtonClick={(user) => onAssigneeSelect(user)}
                      />
                    </View>
                  </Accordion>

                  <Accordion
                    className={styles['po-detail__left-column__accordion']}
                    headerOptions={{
                      triggerInnerClassName:
                        styles['po-detail__left-column__accordion__trigger-inner'],
                      headerElement: (
                        <View direction="row">
                          <View.Item grow>
                            <Text
                              color="primary"
                              size="100"
                              weight="bold"
                              className={styles['po-detail__left-column__accordion__header']}
                            >
                              {t('PODashboard.PODetails')}
                            </Text>
                          </View.Item>
                          {showPOAction() && (
                            <View.Item>
                              <Link
                                className={
                                  styles['po-detail__left-column__accordion__auxiliary-label__link']
                                }
                                onClick={(
                                  event:
                                    | React.MouseEvent<HTMLElement>
                                    | React.KeyboardEvent<HTMLElement>
                                ) => {
                                  setShowPOEditModal(true);
                                  event.stopPropagation();
                                }}
                              >
                                {t('Edit')}
                              </Link>
                            </View.Item>
                          )}
                        </View>
                      ),
                    }}
                  >
                    <PODetailsSection
                      inboundOrderData={inboundOrderData}
                      trailerOrder={trailerOrderData}
                      isHighPriority={isHighPriority}
                      priorityEnabled={isLoadingUpdate || !showPOAction()}
                      onChangePriority={onChangePriority}
                    />
                  </Accordion>

                  <Accordion
                    className={styles['po-detail__left-column__accordion']}
                    headerOptions={{
                      triggerInnerClassName:
                        styles['po-detail__left-column__accordion__trigger-inner'],
                      headerElement: (
                        <View direction="row">
                          <Text
                            color="primary"
                            size="100"
                            weight="bold"
                            className={styles['po-detail__left-column__accordion__header']}
                          >
                            {t('PODashboard.SCAContactInformation')}
                          </Text>
                        </View>
                      ),
                    }}
                  >
                    <View padding={[4, 0]}>
                      <Text color="primary" size="087" weight="medium">
                        {t('PODashboard.SCAContactInformation')}
                      </Text>
                    </View>
                  </Accordion>

                  {showPOAction() && (
                    <View
                      padding={6}
                      className={styles['po-detail__left-column__remove-po_wrapper']}
                    >
                      <Link
                        className={styles['po-detail__left-column__remove-po_wrapper__action']}
                        onClick={() => {
                          return;
                        }}
                      >
                        <View direction="row" justify="center" align="center" gap={2}>
                          <Icon svg={ActionDelete} color="error" />
                          <Text color="error">{t('PODashboard.RemovePO.ActionText')}</Text>
                        </View>
                      </Link>
                    </View>
                  )}
                </View>
              </View.Item>

              <View.Item
                grow
                className={styles['po-detail__right-column']}
                attributes={{
                  'data-testid': 'po-details-right-column',
                }}
              >
                <PODetailStatatics inboundOrderData={inboundOrderData} />

                {isPOStatus(TRAILER_ORDER_STATUS.HOLD) && (
                  <View padding={[4, 6]}>
                    <Notification
                      {...WMSInlineNotification.snack}
                      title={t('PODashboard.PutOnHold.InlineNotificationTitle')}
                      text={t('PODashboard.PutOnHold.InlineNotificationText')}
                      ctaLabel={
                        <View direction="row" justify="center" gap={2}>
                          <View.Item>
                            <Text color="secondary" weight="medium" lineHeight="140">
                              {t('PODashboard.Actions.RemoveHold')}
                            </Text>
                          </View.Item>

                          <View.Item>
                            <Icon svg={ArrowRight} color="secondary" />
                          </View.Item>
                        </View>
                      }
                      ctaType="button"
                      ctaOnClick={() => setShowPOHoldModal(true)}
                    />
                  </View>
                )}

                {isPOStatus(TRAILER_ORDER_STATUS.READY_FOR_SIGNATURE) &&
                  inboundOrderData.trailerOrder.inboundOrder.subStatusCd ===
                    INBOUND_ORDER_STATUS.ACCEPTED && (
                    <View padding={[4, 6]}>
                      <Notification
                        type="custom"
                        className={styles['po-detail__notification_success']}
                        customNotification={{
                          svg: CheckmarkCircle,
                          barColor: 'success',
                          iconColor: 'success',
                        }}
                        title={t('PODashboard.ReadyForSignature.InlineNotificationTitle')}
                        text={t('PODashboard.ReadyForSignature.InlineNotificationText')}
                      />
                    </View>
                  )}

                {isPOICReviewCompleted && (
                  <View padding={[4, 6]}>
                    <Notification
                      type="custom"
                      className={styles['po-detail__notification_success']}
                      customNotification={{
                        svg: CheckmarkCircle,
                        barColor: 'success',
                        iconColor: 'success',
                      }}
                      title={t('PODashboard.ICReviewCompleted.InlineNotificationTitle')}
                      text={t('PODashboard.ICReviewCompleted.InlineNotificationText')}
                    />
                  </View>
                )}

                {isPOStatus(TRAILER_ORDER_STATUS.IN_IC_REVIEW) && (
                  <View padding={[4, 6]}>
                    <Notification
                      type="custom"
                      className={styles['po-detail__notification_warning']}
                      customNotification={{
                        svg: Warning,
                        barColor: 'warning',
                        iconColor: 'warning',
                      }}
                      title={t('PODashboard.InIcReview.InlineNotificationTitle')}
                      text={t('PODashboard.InIcReview.InlineNotificationText')}
                    />
                  </View>
                )}

                {isPORevertReceivingInProgress() && showRevertReceivingInProgressBanner && (
                  <View padding={[4, 6]}>
                    <Notification
                      type="custom"
                      className={styles['po-detail__notification_warning']}
                      customNotification={{
                        svg: Warning,
                        barColor: 'warning',
                        iconColor: 'warning',
                      }}
                      title={t(
                        'PODashboard.RevertReceivingInProgress.InlineNotificationTitle'
                      ).toUpperCase()}
                      text={
                        <View className={styles['po-detail__notification_text']}>
                          <Text size="100">
                            {inboundOrderData.trailerOrder.inboundOrder.statusRevertReason}
                          </Text>
                        </View>
                      }
                      hasCloseButton
                      onClose={onPORevertReceivingInProgressBannerClose}
                    />
                  </View>
                )}

                {isPOStatus(TRAILER_ORDER_STATUS.READY_FOR_FINALIZATION) &&
                  (inboundOrderData.trailerOrder.inboundOrder.subStatusCd ===
                  INBOUND_ORDER_STATUS.IC_REVIEW_COMPLETED ? (
                    <View padding={[4, 6]}>
                      <Notification
                        type="custom"
                        className={styles['po-detail__notification_success']}
                        customNotification={{
                          svg: CheckmarkCircle,
                          barColor: 'success',
                          iconColor: 'success',
                        }}
                        title={t('PODashboard.ICReviewCompleted.InlineNotificationTitle')}
                        text={t('PODashboard.ICReviewCompleted.InlineNotificationText')}
                      />
                    </View>
                  ) : (
                    <View padding={[4, 6]}>
                      <Notification
                        type="custom"
                        className={styles['po-detail__notification_info']}
                        customNotification={{
                          svg: InfoCircle,
                          barColor: 'info',
                          iconColor: 'info',
                        }}
                        title={t('PODashboard.ReadyForFinalization.InlineNotificationTitle')}
                        text={t('PODashboard.ReadyForFinalization.InlineNotificationText')}
                      />
                    </View>
                  ))}

                <View
                  direction="row"
                  padding={[4, 6]}
                  className={styles['po-detail__right-column__search-wrapper']}
                >
                  <SearchBar
                    className={styles['po-detail__right-column__search-bar']}
                    label={t('PODashboard.Search.DetailsPlaceholder')}
                    maxMenuHeight={300}
                    onValueChange={setSearchValue}
                  />
                </View>

                <View className={styles['po-detail__right-column__product-tabs']}>
                  {isPOStatus(TRAILER_ORDER_STATUS.FINALIZED) ? (
                    <ProductTableSection
                      data={quantityFinalizedData}
                      isLoading={isLoadingInboundOrder}
                      type={QUANTITY_FINALIZED}
                      searchValue={searchValue}
                    />
                  ) : (
                    <ProductTabs tabs={tabs} />
                  )}
                </View>
              </View.Item>
            </View>

            {trailerArrivalEditData && (
              <>
                <EditPOModal
                  isOpen={showPOEditModal}
                  soureOrderNumber={sourceOrderId || ''}
                  receiptId={inboundOrderData.trailerOrder.receiptId}
                  trailerArrivalData={trailerArrivalEditData}
                  onClose={() => onCloseEdit()}
                />

                <RemovePOModal
                  soureOrderNumber={sourceOrderId || ''}
                  receiptId={inboundOrderData.trailerOrder.receiptId}
                  trailerArrivalData={trailerArrivalEditData}
                  isOpen={showPORemoveModal}
                  onClose={() => onCloseRemove()}
                />

                <POStatusModal
                  isOpen={showPOHoldModal}
                  previousStatus={inboundOrderData.trailerOrder.inboundOrder.prevStatusCd || ''}
                  soureOrderNumber={sourceOrderId || ''}
                  receiptId={inboundOrderData.trailerOrder.receiptId}
                  trailerArrivalData={trailerArrivalEditData}
                  onClose={(status) => onClosePutPOHold(status)}
                />

                <FinalizePOModal
                  isOpen={showFinalizePOModal}
                  trailerOrder={trailerOrderData}
                  trailerArrivalData={trailerArrivalEditData}
                  onClose={() => onCloseFinalize()}
                />
              </>
            )}

            {trailerArrivalEditData && recommendedAssigneeData && (
              <AddEditAssigneesModal
                isOpen={showAddEditAssigneesModal}
                existingAssignees={inboundOrderData.trailerOrder.inboundOrder.users}
                recommendedAssignees={recommendedAssigneeData.content?.slice(
                  0,
                  maxRecommendedAssignee
                )}
                soureOrderNumber={sourceOrderId || ''}
                receiptId={inboundOrderData.trailerOrder.receiptId}
                trailerArrivalData={trailerArrivalEditData}
                onClose={() => onCloseAddEditAssignees()}
                onSubmit={() => onSubmitAssignees()}
              />
            )}
          </>
        )}
      </View>
    );
  }
};
