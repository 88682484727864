/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Actionable } from '@az/starc-ui';
import styles from './CombinedPillTabs.module.scss';
import { generateDateString } from '@ofm/utils/utils';
import { getAttributeValueByKey } from '@taskManagement/utils/utils';
import { MasterCard } from '@inbound/components/MasterCard/MasterCard';
import { t } from 'i18next';
import { MAX_VISIBLE_AVATARS_TASK } from '@taskManagement/constants/constants';
import { commonTabCardProps } from './CombinedPillTabs.types';
import { TASK_ATTRIBUTES } from '@shared/constants/constants';

export const CommonTabCard = ({
  onClickAssignCurrentTask,
  poData,
  usersData,
}: commonTabCardProps) => {
  return (
    <>
      <Actionable
        key={poData.taskNo}
        onClick={() => onClickAssignCurrentTask(poData)}
        className={styles['combined-tabs__actionable-card']}
      >
        <MasterCard
          title={getAttributeValueByKey(poData.attributes, TASK_ATTRIBUTES.VENDOR_NAME)}
          priority={!!poData.priority}
          tagItems={[
            {
              text: getAttributeValueByKey(poData.attributes, TASK_ATTRIBUTES.ORDER_TYPE_CD),
              variant: 'order',
            },
            {
              text: getAttributeValueByKey(poData.attributes, TASK_ATTRIBUTES.COMMODITY_TYPE_CD),
              variant: 'order',
            },
          ]}
          label={t('PoTask.Arrival', {
            date: getAttributeValueByKey(poData.attributes, TASK_ATTRIBUTES.TRAILER_ARRIVAL_TS)
              ? generateDateString(
                  new Date(
                    getAttributeValueByKey(poData.attributes, TASK_ATTRIBUTES.TRAILER_ARRIVAL_TS)
                  ),
                  t('DateFormat.ShortTime')
                )
              : '',
          })}
          detailsData={{
            data: [
              {
                label: t('PoTask.PO', {
                  poNumber: getAttributeValueByKey(
                    poData.attributes,
                    TASK_ATTRIBUTES.SOURCE_ORDER_NBR
                  ),
                }),
              },
              {
                label: t('PoTask.RC', {
                  rcNumber: getAttributeValueByKey(
                    poData.attributes,
                    TASK_ATTRIBUTES.ORDER_LOCATION_ID
                  ),
                }),
              },
              {
                label: t('PoTask.Loc', {
                  locationId: getAttributeValueByKey(
                    poData.attributes,
                    TASK_ATTRIBUTES.TRAILER_LOCATION_ID
                  ),
                }),
              },
            ],
          }}
          {...(poData?.assignments && poData?.assignments?.length > 0
            ? {
                avatarGroupData: {
                  users: poData?.assignments?.map((assignment) => {
                    const user = usersData?.find((item) => item.userId === assignment.userId);
                    return {
                      id: parseInt(assignment.userId),
                      firstName: user?.firstName ?? '',
                      lastName: user?.lastName ?? '',
                    };
                  }),
                  size: 'small',
                  variant: 'assignee',
                  maxDisplay: MAX_VISIBLE_AVATARS_TASK,
                },
              }
            : {})}
          piecesData={{
            total:
              parseInt(
                getAttributeValueByKey(poData.attributes, TASK_ATTRIBUTES.TOTAL_ORDERED_QTY)
              ) || 0,
            count:
              parseInt(
                getAttributeValueByKey(poData.attributes, TASK_ATTRIBUTES.TOTAL_RECEIVED_QTY)
              ) || 0,
            label: t('Pieces'),
          }}
          linesData={{
            total:
              parseInt(
                getAttributeValueByKey(poData.attributes, TASK_ATTRIBUTES.TOTAL_LINE_COUNT)
              ) || 0,
            count:
              parseInt(
                getAttributeValueByKey(poData.attributes, TASK_ATTRIBUTES.RECEIVED_LINE_COUNT)
              ) || 0,
            label: t('InboundTaskManager.Lines'),
          }}
        />
      </Actionable>
    </>
  );
};
